import PropTypes from "prop-types";
import TableAction from "../TableAction/TableAction";
import {useHistory} from "react-router";
import {QUESTIONS_MANAGEMENT_PAGE} from "../../utils/routeNames";

import {
    UpdateQuestion,
    DeleteQuestion,
    ViewQuestion
} from "../../actions/QuestionActions";
import {useDispatch} from "react-redux";
import {requestHandler} from "../../infrastructure/requestHandler";
import {useState} from "react";
import {useTranslation} from "react-i18next";

function fillAction(key, label, handleClickEvent) {
    return {key, label, handleClickEvent};
}

function QuestionTableActions({questionId, setParentState, parentState}) {

    const dispatch = useDispatch();
    const history = useHistory();

    const [anchor, setAnchorEl] = useState(false);
    const {t} = useTranslation();

    const handleQuestionViewAction = (id) => {
        dispatch(ViewQuestion());
        history.push(QUESTIONS_MANAGEMENT_PAGE + `/${id}`);
    }

    const handleQuestionEditAction = (id) => {
        dispatch(UpdateQuestion());
        history.push(QUESTIONS_MANAGEMENT_PAGE + `/${id}`);
    }

    const handleQuestionDeleteAction = async (id) => {
        await requestHandler(DeleteQuestion(id), dispatch);
        setParentState(!parentState);
        setAnchorEl(null);
    }

    const questionActions = [
        fillAction("view", t("QUESTIONS_TABLE_ACTIONS_VIEW"), () => handleQuestionViewAction(questionId)),
        fillAction("edit", t("QUESTIONS_TABLE_ACTIONS_EDIT"), () => handleQuestionEditAction(questionId)),
        fillAction("delete", t("QUESTIONS_TABLE_ACTIONS_DELETE"), () => handleQuestionDeleteAction(questionId))
    ];

    return (
        <TableAction anchor={anchor}
                     setAnchorEl={setAnchorEl}
                     actionItems={questionActions}/>
    )
}

QuestionTableActions.propTypes = {
    questionId: PropTypes.string.isRequired,
    setParentState: PropTypes.func,
    parentState: PropTypes.bool
}

export default QuestionTableActions;