import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    selectElement: {
        'min-width': "250px",
        'margin-right': "30px",
        '&:clicked': {
            'border-color': "var(--color-red)"
        }
    },
    textField: {
        'margin-bottom': "15px",
        'width': "100%"
    },
    uploadImageButton: {
        'color': "var(--color-red)",
        'border-color': "var(--color-red)",
        'min-width': "280px",
        '&:hover': {
            background: "var(--color-red-transparent-hover)",
        },
    },
    uploadArea: {
        display: "flex",
        'flex-direction': "row",
        'justify-content': "space-between"
    },
    photoUploadLabel: {
        'color': "var(--color-red)",
        'font-size': "14px",
        'text-align': "center"
    }
}));