import React from 'react';
import styles from './MainContainer.module.css'

import PropTypes from 'prop-types'


export default function MainContainer(props) {

    return (
        <React.Fragment>
            <div className={styles.container}>
                {props.children}
            </div>
        </React.Fragment>
    );
}

MainContainer.propTypes = {
    children: PropTypes.element.isRequired
}