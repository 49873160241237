import React, {useEffect} from "react";
import "./App.css";

import {useSelector} from "react-redux";

import AppRouter from "./AppRouter";
import {useSnackbar} from "notistack";

function App() {

    const {enqueueSnackbar} = useSnackbar();

    const notifications = useSelector(store => store.SnackbarReduce);

    useEffect(() => {
        notifications.messages.forEach(message => {
            enqueueSnackbar(message, {
                variant: notifications.variant,
            })
        });

    }, [notifications])

    return (
        <>
            <AppRouter/>
        </>
    );
}

export default App;