import React from 'react';
import PropTypes from 'prop-types';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: 'white',
        '&:hover > $content': {
            backgroundColor: '#D35F5F30',
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `#FFFFFF20`,
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        color: "white",
        marginRight: theme.spacing(1),
    },
    labelText: {
        color: "white",
        fontSize: "16px",
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));

function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const {labelText, labelIcon: LabelIcon, historyDirection, ...other} = props;

    const history = useHistory();

    const directToRoute = () => {
        if (!!historyDirection) {
            history.push(historyDirection);
        }
    }

    return (
        <TreeItem
            onClick={directToRoute}
            label={
                <div className={classes.labelRoot}>
                    <LabelIcon color="inherit" className={classes.labelIcon}/>
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                </div>
            }
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    labelIcon: PropTypes.elementType.isRequired,
    labelText: PropTypes.string.isRequired,
    historyDirection: PropTypes.string
};

export default StyledTreeItem;