import {Switch, Route, useLocation, Redirect} from "react-router-dom";
import React, {useEffect, useState} from "react";

import routes from "./routes";
import Header from "../../components/Header/Header";
import {QUESTIONS_TAB_PAGE} from "../../utils/routeNames";
import {useTranslation} from "react-i18next";

export default function HomePage() {
    const [value, setValue] = useState(0);
    const location = useLocation();
    const [localizationLanguage, setLocalizationLanguage] = useState("en");

    const {t, i18n} = useTranslation();

    useEffect(() => {
        debugger;
        const lang = localStorage.getItem("localization")

        if (lang === undefined) {
            setLocalizationLanguage("en");
        } else {
            i18n.changeLanguage(lang);
        }
    }, [localizationLanguage]);

    const handleLocalizationChange = (lang) => {
        localStorage.setItem("localization", lang);
        setLocalizationLanguage(lang);
    }

    useEffect(() => {
        const route = routes.find(r => r.path === location.pathname);
        if (route !== undefined) {
            setValue(route.tabIndicator);
        }
    }, [location.pathname])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Header value={value} handleChange={handleChange} handleLocalizationChange={handleLocalizationChange}/>
            <Switch>
                {routes.map((route) => {
                        return <Route component={route.component}
                                      path={route.path}
                                      exact={route.isExact}/>;
                    }
                )}
                <Redirect exact={false} from="/" push to={QUESTIONS_TAB_PAGE}/>
            </Switch>
        </div>
    );
}