import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import UserCheckbox from "../UserCheckbox/UserCheckbox";
import styleClasses from "./ExpirationDatePicker.module.css";
import {Backdrop, Button, Checkbox, Fade, Modal, TextField, withStyles} from "@material-ui/core";
import {useStyles} from "./styles";
import {useTranslation} from "react-i18next";

const CheckboxSelection = withStyles({
    root: {
        '&$checked': {
            color: 'var(--color-yellow-hover)',
        }
    },
    checked: {},
})(Checkbox);

function ExpirationDatePicker(props) {

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isModalOpened, openModal] = useState(false);
    const [selectedUsers, updateSelectedUsers] = useState([]);
    const [allUsersSelected, updateAllUsersSelection] = useState(true);
    const styles = useStyles();

    const {t} = useTranslation();

    useEffect(() => {
        setSelectedDate(new Date(props.expireDate))
    }, [props.expireDate])

    useEffect(() => {
        updateSelectedUsers(props.usersList.map((user) => {
            if (user !== undefined) {
                return user.id
            }
        }));
    }, [props.usersList])

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleOpen = () => {
        openModal(true);
    };

    const handleClose = () => {
        openModal(false);
    };

    const handleUserChoice = (selected, userId) => {
        const foundId = selectedUsers.find(id => id === userId);
        if (foundId === undefined) {
            updateSelectedUsers([...selectedUsers, userId]);
        } else {
            let usersCopyList = [...selectedUsers];
            usersCopyList.splice(usersCopyList.indexOf(foundId), 1);
            updateSelectedUsers(usersCopyList);
        }
    }

    const selectAllUsers = () => {
        const selectedUsersList = [];

        if (!allUsersSelected) {
            props.usersList.forEach((user) => {
                selectedUsersList.push(user.id);
            })
        }

        updateSelectedUsers(selectedUsersList);
        updateAllUsersSelection(!allUsersSelected);
    }

    const handleExpirationUpdate = () => {
        props.handleExpirationUpdate(selectedDate, selectedUsers);
    }

    return (
        <>
            <div className={styleClasses.mainElements}>
                <TextField disabled
                           variant="outlined"
                           label={t("COMPANIES_MNGMT_INPUT_EXPIRE_DATE")}
                           value={props.expireDate ? new Date(props.expireDate).toLocaleDateString() : ""}/>
                <Button variant="outlined" className={styles.manageButton} onClick={handleOpen}>
                    {t("COMPANIES_MNGMT_BUTTON_EXPIRATION_DATE")}
                </Button>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={isModalOpened}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={isModalOpened}>
                    <div className={styles.paper}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={styles.datePicker}
                                variant="inline"
                                format="dd.MM.yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label={t("COMPANIES_MNGMT_LABEL_EXPIRATION_DATE")}
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <div>
                            <Button className={styles.updateButton}
                                    variant="outlined"
                                    onClick={handleExpirationUpdate}>{t("COMPANIES_MNGMT_BUTTON_UPDATE_EXPIRATION_DATE")}</Button>
                            <div className={styleClasses.separationLine}/>
                            {props.forSingleUserPicker ? <></> : <div className={styleClasses.selectAllArea}>
                                <CheckboxSelection onChange={selectAllUsers}
                                                   checked={allUsersSelected}
                                                   id="selectAllList"/>
                                <label className={styleClasses.selectAllLabel}
                                       htmlFor="selectAllList">
                                    {t("COMPANIES_MNGMT_INPUT_EXPIRATION_DATE_SELECT_ALL")}
                                </label>
                            </div>}
                            {props.usersList.map((user) => {
                                if (user !== undefined) {
                                    return (<UserCheckbox showCheckbox={!props.forSingleUserPicker}
                                                          user={user}
                                                          handleCheck={handleUserChoice}
                                                          checked={selectedUsers.find(id => id === user.id) !== undefined}/>);
                                }
                            })}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>);
}

ExpirationDatePicker.propTypes = {
    forSingleUserPicker: PropTypes.bool.isRequired,
    usersList: PropTypes.array.isRequired,
    expireDate: PropTypes.string.isRequired,

    handleExpirationUpdate: PropTypes.func.isRequired
};

export default ExpirationDatePicker;