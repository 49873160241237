import * as actions from "../actions/types/ActionTypes";

const defaultState = {
    token: null,
    uid: null,
    isAuth: false,
    username: null,
};

const LoginReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.LOGIN_USER:
            return {
                ...state,
                username: action.username,
                token: action.token,
                uid: action.uid,
                isAuth: !!action.token,
            };
        case actions.LOGOUT_USER:
            return {
                ...state,
                username: null,
                token: null,
                uid: null,
                isAuth: false,
            };
        default:
            return state;
    }
};

export default LoginReducer;
