import * as action from "../actions/types/ActionTypes";

export const requestHandler = async (handler, dispatch) => {
    try {
        await dispatch({
            type: action.PAGE_IS_LOADING,
        });

        return await dispatch(handler);
    } catch (exception) {
        let errorMessages = [];

        const exceptionData = exception.response.data;
        if (exceptionData.errors) {
            Object.keys(exceptionData.errors).forEach((prop) => {
                errorMessages.push(exceptionData.errors[prop]);
            });
        } else {
            errorMessages.push(exceptionData.message);
        }

        await dispatch({
            type: action.SNACKBAR_ERROR_APPEARED,
            messages: errorMessages,
        });
    } finally {
        await dispatch({
            type: action.PAGE_NOT_LOADING,
            isLoading: false,
        });

        await dispatch({
            type: action.SNACKBAR_CLEAR,
        });
    }
};
