import React, {useEffect, useState} from "react";
import MainContainer from "../../../../components/Containers/MainContainer/MainContainer";
import HeadContainer from "../../../../components/Containers/HeadContainer/HeadContainer";
import {useDispatch, useSelector} from "react-redux";
import {requestHandler} from "../../../../infrastructure/requestHandler";
import AdministratingLeftSidebar
    from "../../../../components/Containers/AdministratingLeftSidebar/AdministratingLeftSidebar";
import AdministratingRightSidebar
    from "../../../../components/Containers/AdministratingRightSidebar/AdministratingRightSidebar";
import NoAdministrationSelectedElement
    from "../../../../components/NoAdministrationSelectedElement/NoAdministrationSelectedElement";
import styleClasses from "../OrdinancesAdministration/OrdinancesAdministration.module.css";
import AdministrationHeader from "../../../../components/AdministrationHeader/AdministrationHeader";
import {Add} from "@material-ui/icons";
import {
    CreateProfession,
    DeleteProfession,
    GetProfessions,
    UpdateProfession
} from "../../../../actions/ProfessionActions";
import ProfessionTreeViewData from "../../../../components/ProfessionTreeViewData/ProfessionTreeViewData";
import ProfessionEditingPage from "./ProfessionEditingPage/ProfessionEditingPage";
import {CreateTopic, DeleteTopic, UpdateTopic} from "../../../../actions/ProfessionTopicActions";
import TopicEditingPage from "./TopicEditingPage/TopicEditingPage";
import {CreateTopicSection, DeleteTopicSection, UpdateTopicSection} from "../../../../actions/TopicSectionActions";
import TopicSectionEditingPage from "./TopicSectionEditingPage/TopicSectionEditingPage";
import {useTranslation} from "react-i18next";


export default function ProfessionsAdministration() {

    const [currentComponentSelection, setCurrentComponentSelection] = useState(<></>);
    const [currentState, setState] = useState(new Date());
    const [componentState, updateComponentState] = useState({
        component: <NoAdministrationSelectedElement/>,
        updateDate: new Date()
    });

    const professionsState = useSelector(state => state.ProfessionsReduce);
    const dispatch = useDispatch();

    const {t} = useTranslation();

    useEffect(async () => {
        requestHandler(GetProfessions(), dispatch);
        setState(new Date());
    }, []);

    useEffect(() => {
        setCurrentComponentSelection(componentState.component);
    }, [componentState])

    const createProfessionHandler = async () => {
        await requestHandler(CreateProfession(), dispatch);
        setState(new Date());
    }

    const createTopicHandler = async (e, data) => {
        const professionId = data.professionId

        await requestHandler(CreateTopic(professionId), dispatch);
        setState(new Date());
    }

    const deleteProfessionHandler = async (professionId) => {
        await requestHandler(DeleteProfession(professionId), dispatch);
        setState(new Date());
    }

    const updateProfessionHandler = async (professionId, title) => {
        await requestHandler(UpdateProfession(professionId, title), dispatch);
        setState(new Date());
    }

    const deleteTopicHandler = async (professionId, topicId) => {
        await requestHandler(DeleteTopic(professionId, topicId), dispatch);
        setState(new Date());
    }

    const updateTopicHandler = async (professionId, topicId, title) => {
        await requestHandler(UpdateTopic(professionId, topicId, title), dispatch);
        setState(new Date());
    }

    const createTopicSectionHandler = async (professionId, topicId, title, chapterId) => {
        await requestHandler(CreateTopicSection(professionId, topicId, title, chapterId), dispatch);
        selectTopicHandler(topicId, professionId)
        setState(new Date());
    }

    const updateTopicSectionHandler = async (professionId, topicId, sectionId, title, chapterId) => {
        debugger;
        await requestHandler(UpdateTopicSection(professionId, topicId, sectionId, title, chapterId), dispatch);
        setState(new Date());
    }

    const deleteTopicSectionHandler = async (professionId, topicId, sectionId) => {
        await requestHandler(DeleteTopicSection(professionId, topicId, sectionId), dispatch)
        setState(new Date());
    }

    const selectProfessionHandler = (professionId) => {

        const selectedProfession = professionsState.professions.find(prof => prof.id === professionId);

        setCurrentComponentSelection(<ProfessionEditingPage
            deleteProfessionHandler={deleteProfessionHandler}
            updateProfessionHandler={updateProfessionHandler}
            profession={selectedProfession}/>)
    }

    const selectTopicHandler = (topicId, professionId) => {

        const foundProfession = professionsState.professions.find(prof => prof.id === professionId);
        const foundTopic = foundProfession.topics.find(topic => topic.id === topicId);

        console.log(foundTopic);

        setCurrentComponentSelection(<></>)
        updateComponentState({
            component: <TopicEditingPage deleteTopicHandler={deleteTopicHandler}
                                         updateTopicHandler={updateTopicHandler}
                                         professionId={professionId}
                                         topic={foundTopic}
                                         professionTitle={foundProfession.title}
                                         createTopicSectionHandler={createTopicSectionHandler}
                                         selectTopicSectionHandler={selectTopicSectionHandler}/>,
            updateDate: new Date()
        });
    }

    const selectTopicSectionHandler = (professionId, topicId, sectionId) => {

        debugger;
        const foundProfession = professionsState.professions.find(prof => prof.id === professionId);
        const foundTopic = foundProfession.topics.find(topic => topic.id === topicId);
        const foundSection = foundTopic.topicSections.find(section => section.id === sectionId);

        setCurrentComponentSelection(<></>)
        updateComponentState({
            component: <TopicSectionEditingPage updateTopicSectionHandler={updateTopicSectionHandler}
                                                deleteTopicSectionHandler={deleteTopicSectionHandler}
                                                topicSection={foundSection}
                                                profession={foundProfession}
                                                topic={foundTopic}/>,
            updateDate: new Date()
        });
    }

    return (
        <div className={styleClasses.containerBg}>
            <HeadContainer>
                <AdministrationHeader
                    header={t("ADMINISTRATION_MENU_ITEM_PROFESSIONS")}
                    buttonText={t("ADMINISTRATION_CONSTANTS_PROFESSIONS_BTN")}
                    buttonIcon={<Add/>}
                    buttonClickHandler={createProfessionHandler}/>
            </HeadContainer>
            <MainContainer>
                <div className={styleClasses.dataContainer}>
                    <AdministratingLeftSidebar>
                        <ProfessionTreeViewData
                            createTopicHandler={createTopicHandler}
                            selectProfessionHandler={selectProfessionHandler}
                            selectTopicHandler={selectTopicHandler}
                            selectTopicSectionHandler={selectTopicSectionHandler}
                            professions={professionsState.professions}/>
                    </AdministratingLeftSidebar>
                    <AdministratingRightSidebar>
                        {currentComponentSelection}
                    </AdministratingRightSidebar>
                </div>
            </MainContainer>
        </div>
    );
}