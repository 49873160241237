import api from "../infrastructure/Api";
import {
    ADD_PROFESSION_TOPIC_SECTION,
    DELETE_PROFESSION_TOPIC_SECTION,
    UPDATE_PROFESSION_TOPIC_SECTION
} from "./types/ActionTypes";

export const CreateTopicSection = (professionId, topicId, title, chapterId) => async (dispatch) => {
    const topicSection = await api.createTopicSection({professionId, topicId, title, chapterId});

    dispatch({
        type: ADD_PROFESSION_TOPIC_SECTION,
        professionId: professionId,
        topicId: topicSection.topicId,
        id: topicSection.id,
        title: topicSection.title,
        ordinanceId: topicSection.ordinanceId,
        ordinanceTitle: topicSection.ordinanceTitle,
        chapterId: topicSection.chapterId,
        chapterTitle: topicSection.chapterTitle
    });
}

export const UpdateTopicSection = (professionId, topicId, id, title, chapterId) => async (dispatch) => {
    const topicSection = await api.updateTopicSection({professionId, topicId, id, title, chapterId});

    debugger;

    dispatch({
        type: UPDATE_PROFESSION_TOPIC_SECTION,
        professionId: professionId,
        topicId: topicSection.topicId,
        id: topicSection.id,
        title: topicSection.title,
        ordinanceId: topicSection.ordinanceId,
        ordinanceTitle: topicSection.ordinanceTitle,
        chapterId: topicSection.chapterId,
        chapterTitle: topicSection.chapterTitle
    });
}

export const DeleteTopicSection = (professionId, topicId, id) => async (dispatch) => {
    const topicSection = await api.deleteTopicSection({professionId, topicId, id});

    dispatch({
        type: DELETE_PROFESSION_TOPIC_SECTION,
        professionId: professionId,
        topicId: topicSection.topicId,
        id: topicSection.id
    });
}