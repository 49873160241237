import * as axios from "axios";
import * as constants from "../utils/constants";
import * as apiRoutes from "../utils/apiRoutes";

class Api {
    constructor() {
        this.client = null;
        this.api_url = `${process.env.REACT_APP_BACKEND_API_URL}`;
    }

    init = (content_type) => {
        let headers = {
            "Content-Type": content_type,
        };

        const userData = localStorage.getItem(constants.LOCAL_STORAGE_KEY);
        if (userData) {
            const parsedValues = JSON.parse(userData);
            headers.authorization = `Bearer ${parsedValues.token}`;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 30000,
            headers: headers,
        });

        return this.client;
    };

    // region Users
    loginUser = async ({Login, Password}) => {
        const formData = new FormData();
        formData.append("Login", Login);
        formData.append("Password", Password);

        return (await this.init("multipart/form-data")
            .post(apiRoutes.user_login(), formData)).data;
    };
    // endregion

    // region Questions
    getQuestions = async ({SearchParameter, SortField, IsAscendingSortOrder, KeepRecords, SkipRecords}) => {
        return (await this.init("application/json")
            .get(apiRoutes.questions_list(), {
                params: {
                    SearchParameter,
                    SortField,
                    IsAscendingSortOrder,
                    KeepRecords,
                    SkipRecords,
                },
            })).data;
    };

    createQuestion = async () => {
        return (await this.init("multipart/form-data")
            .post(apiRoutes.new_question())).data;
    };

    deleteQuestion = async ({id}) => {
        return (await this.init("multipart/form-data")
            .delete(apiRoutes.delete_question({id}))).data;
    };

    getQuestion = async ({id}) => {
        return (await this.init("application/json")
            .get(apiRoutes.get_question({id}))).data;
    };

    updateQuestion = async (id, {OrdinanceId, ChapterId, Text, Explanation, Point}, image, imagePath) => {
        const data = new FormData();

        data.append('OrdinanceId', OrdinanceId);
        data.append('ChapterId', ChapterId);
        data.append('Text', Text);
        data.append('Explanation', Explanation);
        data.append('Point', Point === null ? "" : Point);
        if (image !== undefined) {
            data.append('Image', image, image.name);
        }
        data.append("ImagePath", imagePath === null ? "" : imagePath);

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_question({id}), data)).data;
    };
    // endregion

    // region Ordinances

    getOrdinances = async () => {
        return (await this.init("application/json")
            .get(apiRoutes.get_ordinances())).data;
    };

    getOrdinance = async ({id}) => {
        return (await this.init("application/json")
            .get(apiRoutes.get_ordinance({id}))).data;
    };

    updateOrdinance = async ({id, title, header}) => {
        const data = new FormData();

        data.append('Title', title);
        data.append('Header', header);

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_ordinance({id}), data)).data;
    }

    deleteOrdinance = async ({id}) => {
        return (await this.init("multipart/form-data")
            .delete(apiRoutes.delete_ordinance({id}))).data;
    }

    createOrdinance = async () => {
        return (await this.init("multipart/form-data")
            .post(apiRoutes.new_ordinance())).data;
    }

    // endregion

    // region Image

    getImage = async ({fileName}) => {
        return (await this.init("application/json")
            .get(apiRoutes.get_image({fileName}))).data;
    };

    // endregion

    // region Answers

    getAnswers = async ({questionId}) => {
        return (await this.init("application/json")
            .get(apiRoutes.get_questionAnswers({questionId}))).data;
    }

    updateAnswer = async ({id, text, isCorrect}) => {
        const data = new FormData();

        data.append('Text', text);
        data.append('IsCorrect', isCorrect);

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_Answer({id}), data)).data;
    }

    deleteAnswer = async ({id}) => {
        return (await this.init("multipart/form-data")
            .delete(apiRoutes.delete_Answer({id}))).data;
    }

    createAnswer = async ({questionId}) => {
        return (await this.init("multipart/form-data")
            .post(apiRoutes.new_questionAnswers({questionId}))).data
    }
    // endregion

    // region Chapter

    createChapter = async ({ordinanceId}) => {
        return (await this.init("multipart/form-data")
            .post(apiRoutes.new_chapter({ordinanceId}))).data;
    }

    updateChapter = async ({id, title, header}) => {
        const data = new FormData();

        data.append('Title', title);
        data.append('Header', header);

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_chapter({id}), data)).data;
    }

    deleteChapter = async ({id}) => {
        return (await this.init("multipart/form-data")
            .delete(apiRoutes.delete_chapter({id}))).data;
    }

    // endregion

    // region Professions

    getProfessions = async () => {
        return (await this.init("application/json")
            .get(apiRoutes.get_professions())).data;
    }

    createProfession = async () => {
        return (await this.init("multipart/form-data")
            .post(apiRoutes.new_professions())).data;
    }

    updateProfession = async ({id, title}) => {
        const data = new FormData();

        data.append('Title', title);

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_profession({id}), data)).data;
    }

    deleteProfession = async ({id}) => {
        return (await this.init("multipart/form-data")
            .delete(apiRoutes.delete_profession({id}))).data;
    }

    // endregion

    // region ProfessionTopics

    createTopic = async ({professionId}) => {
        return (await this.init("multipart/form-data")
            .post(apiRoutes.new_topic({professionId}))).data;
    }

    updateTopic = async ({professionId, id, title}) => {
        const data = new FormData();

        data.append('Title', title);

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_topic({professionId, id}), data)).data;
    }

    deleteTopic = async ({professionId, id}) => {
        return (await this.init("multipart/form-data")
            .delete(apiRoutes.delete_topic({professionId, id}))).data;
    }
    // endregion

    // region TopicSections
    createTopicSection = async ({professionId, topicId, title, chapterId}) => {
        const data = new FormData();

        data.append('Title', title);
        data.append('ChapterId', chapterId);

        return (await this.init("multipart/form-data")
            .post(apiRoutes.new_section({professionId, topicId}), data)).data;
    }

    updateTopicSection = async ({professionId, topicId, id, title, chapterId}) => {
        const data = new FormData();

        data.append('Title', title);
        data.append('ChapterId', chapterId);

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_section({professionId, topicId, id}), data)).data;
    }

    deleteTopicSection = async ({professionId, topicId, id}) => {
        return (await this.init("multipart/form-data")
            .delete(apiRoutes.delete_section({professionId, topicId, id}))).data;
    }
    // endregion

    // region Users

    getUsers = async ({SearchParameter, SortField, IsAscendingSortOrder, KeepRecords, SkipRecords}) => {
        return (await this.init("application/json")
            .get(apiRoutes.users_list(), {
                params: {
                    SearchParameter,
                    SortField,
                    IsAscendingSortOrder,
                    KeepRecords,
                    SkipRecords,
                },
            })).data;
    };

    createUser = async () => {
        return (await this.init("multipart/form-data")
            .post(apiRoutes.new_user())).data;
    };

    deleteUser = async ({id}) => {
        return (await this.init("multipart/form-data")
            .delete(apiRoutes.delete_user({id}))).data;
    };

    getUser = async ({id}) => {
        return (await this.init("application/json")
            .get(apiRoutes.get_user({id}))).data;
    };

    updateUserActivity = async ({id, userFree}) => {
        const data = new FormData();

        data.append('UserFree', userFree);

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_user_activity({id}), data)).data;
    }

    updateUser = async ({id, name, email, role, companyId}) => {
        const data = new FormData();

        data.append('Name', name);
        data.append('Email', email ?? "");
        data.append('Role', role);
        data.append('CompanyId', companyId ?? "");

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_user({id}), data)).data;
    }

    updateUserCredentials = async ({id, login, password}) => {
        const data = new FormData();

        data.append('Login', login);
        data.append('Password', password);

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_user_credentials({id}), data)).data;
    }

    updateUserExpiration = async ({id, updatingDate}) => {
        const data = new FormData();

        data.append('ExpireDate', updatingDate.toISOString());

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_user_expiration({id}), data)).data;
    }

    updateUserProfessions = async ({id, professionsList}) => {
        const data = new FormData();

        professionsList.forEach(profId => {
            data.append('Professions', profId);
        })

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_user_professions({id}), data)).data;
    }
    // endregion

    // region Companies

    getDropdownCompanies = async () => {
        return (await this.init("application/json")
            .get(apiRoutes.get_dropdownCompanies())).data;
    }

    getCompanies = async ({SearchParameter, SortField, IsAscendingSortOrder, KeepRecords, SkipRecords}) => {
        return (await this.init("application/json")
            .get(apiRoutes.companies_list(), {
                params: {
                    SearchParameter,
                    SortField,
                    IsAscendingSortOrder,
                    KeepRecords,
                    SkipRecords,
                },
            })).data;
    }

    createCompany = async () => {
        return (await this.init("multipart/form-data")
            .post(apiRoutes.new_company())).data;
    };

    deleteCompany = async ({id}) => {
        return (await this.init("multipart/form-data")
            .delete(apiRoutes.delete_company({id}))).data;
    };

    getCompany = async ({id}) => {
        return (await this.init("application/json")
            .get(apiRoutes.get_company({id}))).data;
    };

    updateCompany = async ({id, title, userCapacity, professionCapacity}) => {
        const data = new FormData();

        data.append('Title', title);
        data.append('UsersCapacity', userCapacity);
        data.append('ProfessionsCapacity', professionCapacity);

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_company({id}), data)).data;
    }

    updateCompanyProfessions = async ({id, professions}) => {
        const data = new FormData();

        professions.forEach(profId => {
            data.append('Professions', profId);
        });

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_company_professions({id}), data)).data;
    }

    updateCompanyUsersExpirationDates = async ({id, users, updatingDate}) => {
        const data = new FormData();

        data.append('ExpireDate', updatingDate.toISOString());
        users.forEach(userId => {
            data.append('Users', userId);
        });

        return (await this.init("multipart/form-data")
            .put(apiRoutes.update_company_user_expirations({id}), data)).data;
    }

    // endregion

    // region Imports

    getImports = async () => {
        return (await this.init("application/json")
            .get(apiRoutes.get_imports_list())).data;
    }

    uploadQuestionsImportDocument = async ({preparationFile, title}) => {
        const data = new FormData();

        data.append('Title', title);
        data.append('PreparationFile', preparationFile);

        return (await this.init("multipart/form-data")
            .post(apiRoutes.upload_questions_import_document(), data)).data;
    }

    startImport = async ({importId}) => {
        return (await this.init("multipart/form-data")
            .post(apiRoutes.start_importing_document(importId))).data;
    }

    // endregion
}

export default new Api();
