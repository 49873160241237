import PropTypes from "prop-types";
import TableAction from "../TableAction/TableAction";

import {useDispatch} from "react-redux";
import {useState} from "react";
import {requestHandler} from "../../infrastructure/requestHandler";
import {StartImportDocument} from "../../actions/ImportActions";
import {useTranslation} from "react-i18next";

function fillAction(key, label, handleClickEvent) {
    return {key, label, handleClickEvent};
}

function ImportTableActions({importId, status, setParentState, parentState}) {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [anchor, setAnchorEl] = useState(false);

    const handleImportStartAction = async (id) => {
        await requestHandler(StartImportDocument(id), dispatch);
        setAnchorEl(false);
    }

    const questionActions = [];

    if(status === 0) {
        questionActions.push(fillAction("view", t("ADMINISTRATION_IMPORT_TABLE_ACTIONS_START_IMPORT"),
                                () => handleImportStartAction(importId)));
    }

    return (
        <TableAction anchor={anchor}
                     setAnchorEl={setAnchorEl}
                     actionItems={questionActions}/>
    )
}

ImportTableActions.propTypes = {
    importId: PropTypes.string.isRequired,
    setParentState: PropTypes.func,
    parentState: PropTypes.bool
}

export default ImportTableActions;