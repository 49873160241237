import React from "react";
import PropTypes from "prop-types";
import styleClasses from "./AdministrationBreadcrumb.module.css"
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {useStyles} from "./styles";

function AdministrationBreadcrumb(props) {
    const classes = useStyles();

    return (
        <div className={styleClasses.breadcrumbRoot}>
            <div className={styleClasses.breadcrumbLabel}>{props.breadCrumbs.join(' - ')}</div>
            {props.deleteParentElementHandler === undefined ? "" :
                <IconButton aria-label="delete"
                            onClick={props.deleteParentElementHandler}
                            className={classes.deleteButton}>
                    <DeleteIcon fontSize="medium"/>
                </IconButton>}
        </div>
    );

}

AdministrationBreadcrumb.propTypes = {
    breadCrumbs: PropTypes.array.isRequired,
    deleteParentElementHandler: PropTypes.func
}

export default AdministrationBreadcrumb;