import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    buttonStyle: {
        backgroundColor: "var(--color-red)",
        color: "white",
        "&:hover" : {
            backgroundColor: "var(--color-red-hover)"
        }
    }
}));