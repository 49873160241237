import * as actions from "../actions/types/ActionTypes";

const defaultState = {
    id: "",
    imagePath: "",
    explanation: "",
    point: "",
    text: "",
    chapter: null,
    ordinance: null,
}

const QuestionReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.QUESTION_SET_DATA : {
            return {
                ...state,
                id : action.id,
                imagePath: action.imagePath,
                explanation: action.explanation,
                point: action.point,
                text: action.text,
                chapter: action.chapter,
                ordinance: action.ordinance
            };
        }
        default:
            return state;
    }
}

export default QuestionReducer;