import {useEffect, useState} from 'react';
import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {requestHandler} from "../../infrastructure/requestHandler";
import {LoginUser} from "../../actions/LoginActions";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import CustomTextForm from "../../components/TextForm/CustomTextForm";
import CustomButton from "../../components/Button/CustomButton";

import classes from "./LoginPage.module.css";

import logo from "../../static/otb_logo.png";
import {useHistory} from 'react-router-dom';
import {QUESTIONS_TAB_PAGE} from '../../utils/routeNames';

import { useTranslation } from 'react-i18next';

const LoginPage = () => {
    const { t, i18n } = useTranslation();

    const [formValue, setFormValue] = useState({
        Login: "",
        Password: "",
    });

    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.LoadingReduce);

    const clickLoginHandler = async () => {
        const loggedIn = await requestHandler(LoginUser({
            Login: formValue.Login,
            Password: formValue.Password
        }), dispatch);

        if(loggedIn) {
            history.push(QUESTIONS_TAB_PAGE);
        }
    };

    const textFormHandler = (e) => {
        setFormValue({...formValue, [e.target.name]: e.target.value});
    };
    return (
        <div className={classes.outerBox}>
            {loading.isLoading && <LoadingSpinner/>}
            <div className={classes.container}>
                <img className={classes.logo_img} src={logo} alt="Logo"/>
                <div className={classes.mainForm}>
                    <CustomTextForm
                        name="Login"
                        onChange={textFormHandler}
                        placeholder={t("LOGIN_PLACEHOLDER")}
                    />
                    <CustomTextForm
                        name="Password"
                        onChange={textFormHandler}
                        style={{marginTop: "20px"}}
                        placeholder="Password"
                        type="password"
                    />
                    <CustomButton
                        onClick={clickLoginHandler}
                        style={{marginTop: "30px"}}
                    >
                        Sign In
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;