import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    tab: {
        color: "#000000",
        "font-size": "18px",
        "font-family": "Roboto",
        textTransform: "none",
        letterSpacing: "2px",
    },
    '@global': {
        ".Mui-selected": {
            "font-weight": "bold",
            "color": "var(--color-red)"
        }
    },
    indicator: {
        "background-color": "var(--color-red)",
    },
}));