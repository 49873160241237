import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import React from "react";
import styleClasses from "./ManagementPageHeader.module.css";

const {Button} = require("@material-ui/core");

const ElementButton = withStyles((theme) => ({
    root: {
        color: "black",
        height: "40px",
        backgroundColor: "var(--color-yellow)",
        '&:hover': {
            backgroundColor: "var(--color-yellow-hover)",
        },
        marginRight: "15px"
    },
}))(Button);

export default function ManagementPageHeader(props) {
    return (
        <div className={styleClasses.elementsPosition}>
            <ElementButton onClick={props.handleLeftSideButtonClick}>
                {props.leftSideButtonText}</ElementButton>
            <ElementButton startIcon={props.rightSideButtonIcon}
                           onClick={props.handleRightSideButtonClick}
                           type={props.rightSideButtonType}>
                {props.rightSideButtonText}</ElementButton>
        </div>
    );
}

ManagementPageHeader.propTypes = {
    handleLeftSideButtonClick: PropTypes.func.isRequired,
    leftSideButtonText: PropTypes.string.isRequired,

    handleRightSideButtonClick: PropTypes.func,
    rightSideButtonText: PropTypes.string.isRequired,
    rightSideButtonIcon: PropTypes.element.isRequired,
    rightSideButtonType: PropTypes.string.isRequired,
}