import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import {StrippedTableRow} from "./styles";

function PreviewTableBody(props) {
    const emptyRows = Math.min(props.rowsPerPage, props.rowsPerPage - props.preparedTableData.length);

    return (
        <TableBody>
            {props.preparedTableData.map((row, index) => {
                return (
                    <StrippedTableRow tabIndex={-1} key={row.position}>
                        <TableCell
                            align="right">{props.currentPage * props.rowsPerPage + index + 1}</TableCell>
                        {[...row]}
                    </StrippedTableRow>
                );
            })}
            {emptyRows > 0 && (
                <StrippedTableRow style={{height: 59 * emptyRows}}>
                    <TableCell colSpan={props.preparedTableData.length > 0 ? props.preparedTableData[0].length + 1 : 100}/>
                </StrippedTableRow>
            )}
        </TableBody>
    );
}

PreviewTableBody.propTypes = {
    currentPage: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    itemsCount: PropTypes.number.isRequired,
    preparedTableData: PropTypes.array.isRequired,
    handleTakingRows: PropTypes.func.isRequired,
    handleSkippingRows: PropTypes.func.isRequired,
}

export default PreviewTableBody;