import React, {useEffect, useState} from 'react';
import styleClasses from "../OrdinancesAdministration/OrdinancesAdministration.module.css";
import HeadContainer from "../../../../components/Containers/HeadContainer/HeadContainer";
import MainContainer from "../../../../components/Containers/MainContainer/MainContainer";
import CreateImportButton from "../../../../components/CreateImportButton/CreateImportButton";
import ImportsTable from "./ImportsTable/ImportsTable";
import {requestHandler} from "../../../../infrastructure/requestHandler";
import {GetQuestionImports} from "../../../../actions/ImportActions";
import {useDispatch} from "react-redux";
import * as signalR from "@microsoft/signalr";
import {sendImportProgressNotificationKey} from "../../../../infrastructure/signalR/constants";

export default function QuestionImportsPage() {

    const dispatch = useDispatch();

    const [importsList, setImportsList] = useState([]);
    const [importIdToUpdate, setImportIdToUpdate] = useState({id: undefined, importProgress: -1});

    useEffect(() => {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_BACKEND_API_URL}hubs/import`, {
                transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling
            })
            .withAutomaticReconnect()
            .build();
        hubConnection.start()
            .then(() => {
                console.log('Import hub connected!');
                hubConnection.on(sendImportProgressNotificationKey, (importId, importName, importProgress) => {
                    debugger;
                    if (importProgress === 100) {
                        updateListOfImports();
                    } else {
                        setImportIdToUpdate({id: importId, importProgress: importProgress});
                    }
                });
            })
            .catch(e => console.log('Connection failed: ', e));
    }, []);

    useEffect(() => {
        updateListOfImports();
    }, [])

    useEffect(() => {
        if(importIdToUpdate.id !== undefined) {
            debugger;
            const imports = importsList.map(i => {
                if (i.id === importIdToUpdate.id) {
                    i.importProgress = importIdToUpdate.importProgress;
                }

                return i;
            });

            setImportsList(imports);
        }
    }, [importIdToUpdate])

    const updateListOfImports = async () => {
        const imports = await requestHandler(GetQuestionImports(), dispatch);
        imports.forEach(i => i.importProgress = -1);

        setImportsList(imports);
    }

    return (
        <div className={styleClasses.containerBg}>
            <HeadContainer>
                <CreateImportButton reloadData={updateListOfImports}/>
            </HeadContainer>
            <MainContainer>
                <ImportsTable importsList={importsList}/>
            </MainContainer>
        </div>
    );
}