import {
    SET_ORDINANCES_LIST,
    UPDATE_ORDINANCE,
    DELETE_ORDINANCE,
    ADD_ORDINANCE,
    ADD_ORDINANCE_CHAPTER,
    UPDATE_ORDINANCE_CHAPTER,
    DELETE_ORDINANCE_CHAPTER
} from "../actions/types/ActionTypes";

const defaultState = {
    ordinances: []
};

const OrdinancesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_ORDINANCES_LIST: {
            return {
                ordinances: action.ordinances.map(ordinance => {

                    return {
                        id: ordinance.id,
                        title: ordinance.title ? ordinance.title : "",
                        header: ordinance.header ? ordinance.header : "",
                        chapters: ordinance.chapters.map(chapter => {
                            return {
                                id: chapter.id,
                                title: chapter.title ? chapter.title : "",
                                header: chapter.header ? chapter.header : ""
                            }
                        })
                    }
                })
            };
        }
        case UPDATE_ORDINANCE: {
            state.ordinances.forEach(ordinance => {
                if (ordinance.id === action.id) {
                    ordinance.title = action.title ? action.title : "";
                    ordinance.header = action.header ? action.header : "";
                    ordinance.chapters = action.chapters;
                }
            });

            return state;
        }
        case DELETE_ORDINANCE: {
            const deletedElement = state.ordinances.find(ordinance => ordinance.id === action.id);
            state.ordinances.splice(state.ordinances.indexOf(deletedElement), 1);

            return state;
        }
        case ADD_ORDINANCE: {
            return {
                ordinances: [...state.ordinances, {
                    id: action.id,
                    title: action.title ? action.title : "",
                    header: action.header ? action.header : "",
                    chapters: action.chapters
                }]
            };
        }
        case ADD_ORDINANCE_CHAPTER: {
            const foundOrdinance = state.ordinances.find(ordinance => ordinance.id === action.ordinanceId);

            foundOrdinance.chapters = [...foundOrdinance.chapters, {
                id: action.id,
                title: action.title ? action.title : "",
                header: action.header ? action.header : ""
            }]

            return state;
        }
        case UPDATE_ORDINANCE_CHAPTER: {
            const foundOrdinance = state.ordinances.find(ordinance => ordinance.id === action.ordinanceId);

            foundOrdinance.chapters.forEach(chapter => {
                if (chapter.id === action.id) {
                    chapter.title = action.title ? action.title : "";
                    chapter.header = action.header ? action.header : "";
                }
            });

            return state;
        }
        case DELETE_ORDINANCE_CHAPTER: {
            const foundOrdinance = state.ordinances.find(ordinance => ordinance.id === action.ordinanceId);
            const deletedChapter = foundOrdinance.chapters.find(chapter => chapter.id === action.id);

            foundOrdinance.chapters.splice(foundOrdinance.chapters.indexOf(deletedChapter), 1);

            return state;
        }
        default: {
            return state;
        }
    }
}

export default OrdinancesReducer;