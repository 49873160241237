import api from "../infrastructure/Api";
import {
    SET_NEW_PAGE_STATUS,
    SET_TABLE_DATA,
    SET_UPDATING_PAGE_STATUS,
    SET_VIEW_PAGE_STATUS,
    SNACKBAR_SUCCESS_APPEARED
} from "./types/ActionTypes";
import * as action from "./types/ActionTypes";

export const FetchUsersTableData = (state) => async (dispatch) => {
    let fetchResult = await api.getUsers({
        SkipRecords: state.currentPage * state.takeRows,
        KeepRecords: state.takeRows,
        IsAscendingSortOrder: state.sortAscending,
        SortField: state.sortField,
        SearchParameter: state.searchParameter,
    });

    dispatch({
        type: SET_TABLE_DATA,
        tableData: fetchResult.pagedElements,
        tableTotalDataCount: fetchResult.count,
    });
};

export const CreateNewUser = () => async (dispatch) => {
    const user = await api.createUser();

    dispatch({
        type: SET_NEW_PAGE_STATUS,
    });

    return user;
}

export const DeleteUser = (id) => async (dispatch) => {
    return await api.deleteUser({id});
}

export const ViewUser = () => async (dispatch) => {
    dispatch({
        type: SET_VIEW_PAGE_STATUS,
    });
}
export const GetUserData = (id) => async (dispatch) => {
    return await api.getUser({id});
}

export const UpdateUser = () => async (dispatch) => {
    dispatch({
        type: SET_UPDATING_PAGE_STATUS
    });
}

export const UpdateUserData = (id, name, email, role, companyId) => async (dispatch) => {

    await api.updateUser({id, name, email, role, companyId})

    dispatch({
        type: SET_VIEW_PAGE_STATUS,
    });
}

export const ChangeUserActivityStatus = (id, userFree) => async (dispatch) => {
    return await api.updateUserActivity({id, userFree})
}

export const UpdateUserCredentials = (id, login, password) => async (dispatch) => {
    const user = await api.updateUserCredentials({id, login, password});

    await dispatch({
        type: SNACKBAR_SUCCESS_APPEARED,
        messages: ["User's login successfully updated!"],
    });

    return user.login;
}

export const UpdateUserExpiration = (id, updatingDate) => async (dispatch) => {
    return await api.updateUserExpiration({id, updatingDate});
}

export const UpdateUserProfessions = (id, professionsList) => async (dispatch) => {
    const user = await api.updateUserProfessions({id, professionsList});

    return user.professions;
}