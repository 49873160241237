export const HOME_PAGE = "/";
export const LOGIN_PAGE = "/login";
export const NOT_FOUND = "/error-404-not-found";

export const QUESTIONS_TAB_PAGE = HOME_PAGE + "questions";
export const USERS_TAB_PAGE = HOME_PAGE + "users";
export const COMPANIES_TAB_PAGE = HOME_PAGE + "companies";
export const ADMINISTRATION_PAGE = HOME_PAGE + "administration";

export const QUESTIONS_MANAGEMENT_PAGE = QUESTIONS_TAB_PAGE + "/management";
export const QUESTIONS_MANAGEMENT_ROUTE_PAGE = QUESTIONS_MANAGEMENT_PAGE + "/:id";

export const USERS_MANAGEMENT_PAGE = USERS_TAB_PAGE + "/management";
export const USERS_MANAGEMENT_ROUTE_PAGE = USERS_MANAGEMENT_PAGE + "/:id";

export const COMPANIES_MANAGEMENT_PAGE = COMPANIES_TAB_PAGE + "/management";
export const COMPANIES_MANAGEMENT_ROUTE_PAGE = COMPANIES_MANAGEMENT_PAGE + "/:id";

export const ORDINANCES_ADMINISTRATION_PAGE = ADMINISTRATION_PAGE + "/ordinances";
export const PROFESSIONS_ADMINISTRATION_PAGE = ADMINISTRATION_PAGE + "/professions";
export const IMPORTS_ADMINISTRATION_PAGE = ADMINISTRATION_PAGE + "/imports";