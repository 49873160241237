import api from "../infrastructure/Api";
import {SET_NEW_PAGE_STATUS, SET_TABLE_DATA, SET_UPDATING_PAGE_STATUS, SET_VIEW_PAGE_STATUS} from "./types/ActionTypes";

export const GetDropdownCompanies = () => async (dispatch) => {
    return await api.getDropdownCompanies();
}

export const FetchCompaniesTableData = (state) => async (dispatch) => {
    const fetchResult = await api.getCompanies({
        SkipRecords: state.currentPage * state.takeRows,
        KeepRecords: state.takeRows,
        IsAscendingSortOrder: state.sortAscending,
        SortField: state.sortField,
        SearchParameter: state.searchParameter,
    });

    dispatch({
        type: SET_TABLE_DATA,
        tableData: fetchResult.pagedElements,
        tableTotalDataCount: fetchResult.count,
    });
}

export const CreateNewCompany = () => async (dispatch) => {
    const company = await api.createCompany();

    dispatch({
        type: SET_NEW_PAGE_STATUS,
    });

    return company;
}

export const DeleteCompany = (id) => async (dispatch) => {
    return await api.deleteCompany({id});
}

export const ViewCompany = () => async (dispatch) => {
    dispatch({
        type: SET_VIEW_PAGE_STATUS,
    });
}

export const GetCompanyData = (id) => async (dispatch) => {
    return await api.getCompany({id});
}

export const UpdateCompany = () => async (dispatch) => {
    dispatch({
        type: SET_UPDATING_PAGE_STATUS
    });
}

export const UpdateCompanyData = (id, title, userCapacity, professionCapacity) => async (dispatch) => {
    const company = await api.updateCompany({id, title, userCapacity, professionCapacity});

    dispatch({
        type: SET_VIEW_PAGE_STATUS,
    });

    return company;
}

export const UpdateCompanyProfessions = (id, professions) => async (dispatch) => {
    const company = await api.updateCompanyProfessions({id, professions})

    return company.professions;
}

export const UpdateCompanyUsersExpirationDates = (id, users, updatingDate) => async (dispatch) => {
    return await api.updateCompanyUsersExpirationDates({id, users, updatingDate})
}