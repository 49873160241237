import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
    Backdrop,
    Button,
    Fade,
    InputLabel,
    MenuItem,
    Modal,
    Select
} from "@material-ui/core";
import styleClasses from "./ProfessionsPickerModal.modal.css";
import {useStyles} from "./styles";
import ProfessionChipsArea from "../ProfessionChipsArea/ProfessionChipsArea";
import {requestHandler} from "../../infrastructure/requestHandler";
import {GetProfessions} from "../../actions/ProfessionActions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

function ProfessionsPickerModal(props) {

    const [isModalOpened, openModal] = useState(false);
    const [userProfessions, updateUserProfessions] = useState([]);
    const [userStaticProfessionsList, updateUserStaticProfessionsList] = useState([]);
    const [professionsList, setProfessionsList] = useState([]);
    const [dropdownProfessions, setDropdownProfessions] = useState([]);

    const dispatch = useDispatch();
    const styles = useStyles();

    const {t} = useTranslation();

    useEffect(async () => {
        const setupProfessions = async () => {
            const result = await requestHandler(GetProfessions(), dispatch);

            setProfessionsList(result.professions.map((prof) => {
                return {
                    id: prof.id,
                    title: prof.title
                };
            }))
        };

        setupProfessions();
    }, []);

    useEffect(() => {

        const pastedProfession = [];
        props.userProfessions.forEach((profession) => {
            if (profession !== undefined) {
                pastedProfession.push({
                    id: profession.id,
                    title: profession.title
                });
            }
        });

        const withoutDuplicates = [];
        professionsList.forEach((prof) => {
            if (pastedProfession.find(p => p.id === prof.id) === undefined) {
                withoutDuplicates.push(prof);
            }
        })

        updateUserProfessions(pastedProfession);
        updateUserStaticProfessionsList(pastedProfession);
        setDropdownProfessions(withoutDuplicates);
    }, [props.userProfessions, professionsList]);

    const handleSaveProfessionsList = () => {
        props.saveProfessionsListHandler(userProfessions);
    };

    const handleAddProfession = (event) => {
        const profession = event.target;

        let dropdownCopy = [...dropdownProfessions];
        const foundElement = dropdownCopy.find(p => p.id === profession.value);

        dropdownCopy.splice(dropdownCopy.indexOf(foundElement), 1);

        setDropdownProfessions(dropdownCopy);
        updateUserProfessions([...userProfessions, foundElement])
    };

    const handleRemoveProfession = (profession) => {
        const foundProfession = userProfessions.find(p => p.id === profession.id);

        let userProfessionsCopy = [...userProfessions];
        userProfessionsCopy.splice(userProfessionsCopy.indexOf(foundProfession), 1);

        setDropdownProfessions([...dropdownProfessions, foundProfession]);
        updateUserProfessions(userProfessionsCopy);
    };

    const handleOpen = () => {
        openModal(true);
    };

    const handleClose = () => {
        openModal(false);
    };

    return (
        <div>
            <div className={styleClasses.mainElements}>
                <ProfessionChipsArea areaDisabled={true}
                                     professions={userStaticProfessionsList}/>
                <Button variant="outlined" className={styles.manageButton} onClick={handleOpen}>
                    {t("COMPANIES_MNGMT_INPUT_PROFS_BUTTON")}
                </Button>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={isModalOpened}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={isModalOpened}>
                    <div className={styles.paper}>
                        <div className={styleClasses.modalChipsArea}>
                            <ProfessionChipsArea areaDisabled={false}
                                                 professions={userProfessions}
                                                 handleDelete={handleRemoveProfession}/>
                        </div>
                        <div className={styles.chipsSelectionArea}>
                            <InputLabel shrink id="professions-select-label">
                                {t("COMPANIES_MNGMT_INPUT_PROFS_LABEL")}
                            </InputLabel>
                            <Select
                                name="professions"
                                className={styles.pickerInput}
                                onChange={handleAddProfession}
                                value=""
                                labelId="professions-select-label"
                                label={t("COMPANIES_MNGMT_INPUT_PROFS_LABEL")}>
                                {dropdownProfessions.map(profession => {
                                    return <MenuItem value={profession.id}>{profession.title}</MenuItem>
                                })}
                            </Select>
                        </div>
                        <Button variant="outlined"
                                className={styles.updateButton}
                                onClick={handleSaveProfessionsList}>
                            {t("COMPANIES_MNGMT_INPUT_PROFS_UPDATE_BUTTON")}
                        </Button>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

ProfessionsPickerModal.propTypes = {
    userProfessions: PropTypes.array.isRequired,
    saveProfessionsListHandler: PropTypes.func.isRequired
};

export default ProfessionsPickerModal;