import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styleClasses from "./TopicEditingPage.module.css";
import AdministrationBreadcrumb from "../../../../../components/AdministrationBreadcrumb/AdministrationBreadcrumb";
import AdministrationInput from "../../../../../components/AdministrationInput/AdministrationInput";
import {useStyles} from "./styles";
import AddBoxIcon from '@material-ui/icons/AddBox';
import {IconButton} from "@material-ui/core";
import TopicSectionPreview from "../../../../../components/TopicSectionPreview/TopicSectionPreview";
import TopicSectionManagementComponent
    from "../../../../../components/TopicSectionManagementComponent/TopicSectionManagementComponent";
import {useTranslation} from "react-i18next";

function TopicEditingPage(props) {

    const breadCrumbs = [props.professionTitle];
    const classes = useStyles();
    const {t} = useTranslation();

    const changeTopicTitle = (value) => {
        props.updateTopicHandler(props.professionId, props.topic.id, value);
    }

    const deleteTopicHandler = () => {
        props.deleteTopicHandler(props.professionId, props.topic.id)
    }

    const createTopicSection = (values) => {
        props.createTopicSectionHandler(props.professionId, props.topic.id, values.title, values.chapter);
    }

    const submitActionButton =
        <IconButton className={classes.submitButton}
                    type="submit">
            <AddBoxIcon fontSize="large"/>
        </IconButton>;

    return (
        <div className={styleClasses.rootArea}>
            <AdministrationBreadcrumb breadCrumbs={breadCrumbs}
                                      deleteParentElementHandler={deleteTopicHandler}/>
            <div className={styleClasses.managingArea}>
                <AdministrationInput changeHandler={changeTopicTitle}
                                     inputTitle={t("ADMINISTRATION_PROFESSIONS_TOPIC_TITLE")}
                                     className={classes.elementPosition}
                                     inputValue={props.topic.title === null ? "" : props.topic.title}/>
                <div className={styleClasses.separatedSection}>
                    <TopicSectionManagementComponent isEditable={true}
                                                   buttonElement={submitActionButton}
                                                   formSubmitHandler={createTopicSection}
                                                   topicSection={undefined}/>
                </div>
                {props.topic.topicSections.map(section => {
                    return <TopicSectionPreview topicSection={section}
                                                professionId={props.professionId}
                                                topicId={props.topic.id}
                                                selectTopicSectionHandler={props.selectTopicSectionHandler}/>
                })}
            </div>
        </div>
    );
}

TopicEditingPage.propTypes = {
    deleteTopicHandler: PropTypes.func.isRequired,
    updateTopicHandler: PropTypes.func.isRequired,

    createTopicSectionHandler: PropTypes.func.isRequired,
    selectTopicSectionHandler: PropTypes.func.isRequired,

    professionId: PropTypes.string.isRequired,
    professionTitle: PropTypes.string,
    topic: PropTypes.object,
};

export default TopicEditingPage;