import React, {useEffect, useState} from "react";
import {Backdrop, Button, Fade, Modal} from "@material-ui/core";
import {useStyles} from "./styles";
import PropTypes from "prop-types";
import styleClasses from "./AnswersManagementModal.module.css";
import {useDispatch, useSelector} from "react-redux";

import {CreateAnswer, DeleteAnswer, GetQuestionAnswers, UpdateAnswer} from "../../actions/AnswerActions";
import {requestHandler} from "../../infrastructure/requestHandler";
import SearchField from "../TableHeader/SearchField/SearchField";
import {DelayInput} from "react-delay-input/lib/Component";
import AnswerInput from "../AnswerInput/AnswerInput";
import {useTranslation} from "react-i18next";

export default function AnswersManagementModal(props) {
    const [isModalOpened, openModal] = useState(false);
    const [modalState, updateState] = useState(false);

    const dispatch = useDispatch();
    const styles = useStyles();

    const answersState = useSelector(state => state.AnswersReduce);

    const {t} = useTranslation();

    useEffect(async () => {
        if (isModalOpened) {
            await requestHandler(GetQuestionAnswers(props.questionId), dispatch);
        }
    }, [isModalOpened, modalState])

    const handleOpen = () => {
        openModal(true);
    };

    const handleClose = () => {
        openModal(false);
    };

    const updateAnswerHandler = async ({value, id, isCorrect}) => {
        await requestHandler(UpdateAnswer(id, {text: value, isCorrect}), dispatch);
    }

    const setCorrectAnswerHandler = async ({value, id, isCorrect}) => {
        await requestHandler(UpdateAnswer(id, {text: value, isCorrect}), dispatch);
    }

    const deleteAnswerHandler = async (id) => {
        await requestHandler(DeleteAnswer(id), dispatch);
        updateState(!modalState);
    }

    const createAnswerHandler = async () => {
        await requestHandler(CreateAnswer(props.questionId), dispatch);
        updateState(!modalState);
    }

    return (
        <div>
            <Button variant="outlined" className={styles.answersButton} onClick={handleOpen}>
                {t("ANSWERS_MODAL_BTN_TITLE")}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={isModalOpened}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isModalOpened}>
                    <div className={styles.paper}>
                        <label className={styleClasses.questionHeaderLabel}>
                            {t("ANSWERS_MODAL_QUESTION_TITLE")} {props.questionId}
                        </label>
                        <div className={styleClasses.separationLine}/>
                        <Button variant="outlined"
                                className={styles.addAnswerButton}
                                onClick={createAnswerHandler}>
                            {t("ANSWERS_MODAL_ADD_BTN")}
                        </Button>
                        <div className={styleClasses.separationLine}/>
                        <div className={styleClasses.answersList}>
                            {answersState.answers.map(answer => {
                                return (
                                    <DelayInput
                                        id={answer.id}
                                        text={answer.text}
                                        isCorrect={answer.isCorrect}
                                        delayTimeout={500}
                                        setCorrectAnswerHandler={setCorrectAnswerHandler}
                                        deleteAnswerHandler={deleteAnswerHandler}
                                        value={answer.text}
                                        onChange={event => updateAnswerHandler({
                                            value: event.target.value,
                                            id: answer.id,
                                            isCorrect: answer.isCorrect
                                        })}
                                        element={AnswerInput}/>
                                )
                            })}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

AnswersManagementModal.props = {
    questionId: PropTypes.string.isRequired
};