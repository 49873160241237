import * as actions from "../actions/types/ActionTypes";

const defaultState = {
    imports: []
}

const ImportReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.GET_QUESTION_IMPORTS:
            return {
                ...state,
                imports: action.imports
            };
        default: {
            return state;
        }
    }
}

export default ImportReducer;