import {
    ADMINISTRATION_PAGE, IMPORTS_ADMINISTRATION_PAGE,
    ORDINANCES_ADMINISTRATION_PAGE,
    PROFESSIONS_ADMINISTRATION_PAGE
} from "../../../utils/routeNames";

import OrdinancesAdministration from "./OrdinancesAdministration/OrdinancesAdministration";
import ProfessionsAdministration from "./ProfessionsAdministration/ProfesionsAdministration";
import QuestionImportsPage from "./QuestionImportsPage/QuestionImports";

export default [
    ManageApplicationRoutes(ADMINISTRATION_PAGE, null, true),
    ManageApplicationRoutes(ORDINANCES_ADMINISTRATION_PAGE, OrdinancesAdministration, true),
    ManageApplicationRoutes(PROFESSIONS_ADMINISTRATION_PAGE, ProfessionsAdministration, true),
    ManageApplicationRoutes(IMPORTS_ADMINISTRATION_PAGE, QuestionImportsPage, true),
];

function ManageApplicationRoutes(path, component, isExact) {
    return {
        path,
        component,
        isExact
    };
}