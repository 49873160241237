import React from "react";
import PropTypes from "prop-types";
import {useStyles} from "./styles";
import styleClasses from "./ProfessionEditingPage.module.css";
import AdministrationBreadcrumb from "../../../../../components/AdministrationBreadcrumb/AdministrationBreadcrumb";
import AdministrationInput from "../../../../../components/AdministrationInput/AdministrationInput";
import {useTranslation} from "react-i18next";

function ProfessionEditingPage(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const breadCrumbs = [t("ADMINISTRATION_MENU_ITEM_PROFESSIONS")];

    const changeProfessionTitle = (value) => {
        props.updateProfessionHandler(props.profession.id, value);
    }

    const deleteProfessionHandler = () => {
        props.deleteProfessionHandler(props.profession.id)
    }

    return (
        <div className={styleClasses.rootArea}>
            <AdministrationBreadcrumb breadCrumbs={breadCrumbs}
                                      deleteParentElementHandler={deleteProfessionHandler}/>
            <div className={styleClasses.managingArea}>
                <AdministrationInput changeHandler={changeProfessionTitle}
                                     inputTitle={t("ADMINISTRATION_PROFESSIONS_EDIT_INPUT_TITLE")}
                                     className={classes.elementPosition}
                                     inputValue={props.profession.title === null ? "" : props.profession.title}/>
            </div>
        </div>
    );
}

ProfessionEditingPage.propTypes = {
    deleteProfessionHandler: PropTypes.func.isRequired,
    updateProfessionHandler: PropTypes.func.isRequired,

    profession: PropTypes.object
};

export default ProfessionEditingPage;