import React, {useEffect, useState} from 'react';
import {Backdrop, Button, Fade, Modal, TextField, withStyles} from "@material-ui/core";
import {useStyles} from "./styles";
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import styleClasses from "../ExpirationDatePicker/ExpirationDatePicker.module.css";
import FormTextFieldElement from "../FormTextFieldElement/FormTextFieldElement";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {requestHandler} from "../../infrastructure/requestHandler";
import {UploadImportDocument} from "../../actions/ImportActions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

function CreateImportButton(props) {
    const styles = useStyles();
    const dispatch = useDispatch();

    const {t} = useTranslation();

    const [isModalOpened, openModal] = useState(false);
    const [importTitle, setImportTitle] = useState('');
    const [document, setDocument] = useState(undefined);
    const [buttonEnabled, setButtonEnabled] = useState(false);

    useEffect(() => {
        setImportTitle('');
        setDocument(undefined);
        setButtonEnabled(false);
    }, [isModalOpened])

    useEffect(() => {
        setButtonEnabled(document !== undefined && importTitle !== '');
    }, [importTitle, document])

    const handleImportCreation = async () => {
        await requestHandler(UploadImportDocument(document, importTitle), dispatch);

        props.reloadData();
        openModal(false);
    }

    const handleOpen = () => {
        openModal(true);
    };

    const handleClose = () => {
        openModal(false);
    };

    const handleDocumentChoice = (event) => {
        setDocument(event.target.files[0]);
    }

    const handleTitleNameChanging = (event) => {
        setImportTitle(event.currentTarget.value);
    }

    return (
        <>
            <div className={styles.headerContainer}>
                <Button className={styles.importButton} onClick={handleOpen}>
                    <LibraryAddIcon className={styles.iconPaddings}/> {t("ADMINISTRATION_IMPORT_BTN")}
                </Button>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={isModalOpened}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={isModalOpened}>
                    <div className={styles.paper}>
                        <div className={styles.modalContainer}>
                            <FormTextFieldElement
                                disabled={false}
                                onChangeHandler={handleTitleNameChanging}
                                className={styles.inputElement}
                                label={t("ADMINISTRATION_IMPORT_INPUT_TITLE")}/>
                            <Button variant='outlined'
                                    component="label"
                                    className={styles.uploadDocumentButton}>
                                <input hidden type="file" onChange={handleDocumentChoice}/>
                                <AttachFileIcon/> {t("ADMINISTRATION_IMPORT_INPUT_FILE_ATTACH")}
                            </Button>
                            <div className={styleClasses.separationLine}/>
                            <Button className={styles.createImportButton}
                                    variant="outlined"
                                    disabled={!buttonEnabled}
                                    onClick={handleImportCreation}>{t("ADMINISTRATION_IMPORT_DOCUMENT_BTN")}</Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}

export default CreateImportButton;