const {makeStyles} = require("@material-ui/core");

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
        minWidth: '100%'
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));