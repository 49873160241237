import {combineReducers} from "redux";

import LoadingReducer from "./LoadingReducer";
import LoginReducer from "./LoginReducer";
import SnackbarReducer from "./SnackbarReducer";
import TableReducer from "./TableReducer";
import QuestionReducer from "./QuestionReducer";
import PageManagementReducer from "./PageManagementReducer";
import AnswersReducer from "./AnswersReducer";
import OrdinancesReducer from "./OrdinancesReducer";
import ProfessionsReducer from "./ProfessionsReducer";
import ImportReducer from "./ImportReducer";

const RootReducer = combineReducers({
    LoadingReduce: LoadingReducer,
    LoginReduce: LoginReducer,
    SnackbarReduce: SnackbarReducer,
    TableReduce: TableReducer,
    QuestionReduce: QuestionReducer,
    PageManagementReduce: PageManagementReducer,
    AnswersReduce: AnswersReducer,
    OrdinancesReduce: OrdinancesReducer,
    ProfessionsReduce: ProfessionsReducer,
    ImportReduce: ImportReducer,
});

export default RootReducer;