import styles from "./AdministratingRightSidebar.module.css";
import PropTypes from "prop-types";
import React from "react";

function AdministratingRightSidebar(props) {
    return (
        <div className={styles.rootArea}>
            {props.children === undefined ? <></> : props.children}
        </div>
    );
}

AdministratingRightSidebar.propTypes = {
    children: PropTypes.element
}

export default AdministratingRightSidebar;