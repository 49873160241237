import React from "react";
import {Route, Switch} from "react-router-dom";
import routes from "./routes";
import NavContainer from "../../../components/Containers/NavContainer/NavContainer";
import AdministrationMenu from "../../../components/AdministrationMenu/AdministrationMenu";
import classes from "./index.module.css";

export default function AdministrationPage() {
    return (
        <div className={classes.separationArea}>
            <NavContainer>
                <AdministrationMenu/>
            </NavContainer>
            <Switch>
                {routes.map((route) => {
                    return <Route component={route.component}
                                  path={route.path}
                                  exact={route.isExact}/>
                })}
            </Switch>
        </div>
    );
}