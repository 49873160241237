import api from "../infrastructure/Api";
import {
    SET_TABLE_DATA,
    SET_NEW_PAGE_STATUS,
    SET_UPDATING_PAGE_STATUS,
    SET_VIEW_PAGE_STATUS,
    QUESTION_SET_DATA,
} from "./types/ActionTypes";

export const FetchQuestionsTableData = (state) => async (dispatch) => {
    let fetchResult = await api.getQuestions({
        SkipRecords: state.currentPage * state.takeRows,
        KeepRecords: state.takeRows,
        IsAscendingSortOrder: state.sortAscending,
        SortField: state.sortField,
        SearchParameter: state.searchParameter,
    });

    dispatch({
        type: SET_TABLE_DATA,
        tableData: fetchResult.pagedElements,
        tableTotalDataCount: fetchResult.count,
    });
};

export const CreateNewQuestion = () => async (dispatch) => {
    dispatch({
        type: SET_NEW_PAGE_STATUS,
    });

    return await api.createQuestion();
};

export const UpdateQuestion = () => async (dispatch) => {
    dispatch({
        type: SET_UPDATING_PAGE_STATUS,
    });
};

export const ViewQuestion = () => async (dispatch) => {
    dispatch({
        type: SET_VIEW_PAGE_STATUS,
    });
};

export const DeleteQuestion = (id) => async (dispatch) => {
    return await api.deleteQuestion({id});
};

export const GetQuestionData = (id) => async (dispatch) => {
    const question = await api.getQuestion({id});

    dispatch({
        type: QUESTION_SET_DATA,
        id: question.id,
        text: question.text,
        explanation: question.explanation,
        imagePath: question.imagePath,
        point: question.point,
        chapter: question.chapter,
        ordinance: question.ordinance,
    });

    return question;
};

export const UpdateQuestionData =
    (id, question, image, imagePath) => async (dispatch) => {
        const updatedQuestion = await api.updateQuestion(
            id,
            {
                OrdinanceId: question.ordinance,
                ChapterId: question.chapter,
                Text: question.text,
                Explanation: question.explanation,
                Point: question.point,
            },
            image,
            imagePath
        );

        dispatch({
            type: QUESTION_SET_DATA,
            id: updatedQuestion.id,
            text: updatedQuestion.text,
            explanation: updatedQuestion.explanation,
            imagePath: updatedQuestion.imagePath,
            point: updatedQuestion.point,
            chapter: updatedQuestion.chapter,
            ordinance: updatedQuestion.ordinance,
        });

        dispatch({
            type: SET_VIEW_PAGE_STATUS,
        });
    };
