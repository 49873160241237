import React from "react";
import PropTypes from "prop-types";
import styleClasses from "./ChapterEditingPage.module.css"
import AdministrationBreadcrumb from "../../../../../components/AdministrationBreadcrumb/AdministrationBreadcrumb";
import AdministrationInput from "../../../../../components/AdministrationInput/AdministrationInput";

import {useStyles} from "./styles";
import {useTranslation} from "react-i18next";

function ChapterEditingPage(props) {

    const breadCrumbs = [props.ordinanceAdministrationTitle];

    const classes = useStyles();
    const {t} = useTranslation();

    const changeHeaderText = (value) => {
        props.updateChapterHandler(props.chapter.id, props.chapter.title, value);
    }

    const changeTitleText = (value) => {
        props.updateChapterHandler(props.chapter.id, value, props.chapter.header);
    }

    const deleteChapterHandler = () => {
        props.deleteChapterHandler(props.chapter.id)
    }

    return (
        <div className={styleClasses.rootArea}>
            <AdministrationBreadcrumb breadCrumbs={breadCrumbs}
                                      deleteParentElementHandler={deleteChapterHandler}/>
            <div className={styleClasses.managingArea}>
                <AdministrationInput changeHandler={changeHeaderText}
                                     inputTitle={t("ADMINISTRATION_CHAPTERS_EDIT_INPUT_HEADER")}
                                     className={classes.elementPosition}
                                     inputValue={props.chapter.header === null ? "" : props.chapter.header}/>
                <AdministrationInput changeHandler={changeTitleText}
                                     className={classes.elementPosition}
                                     inputTitle={t("ADMINISTRATION_CHAPTERS_EDIT_INPUT_ADMINTITLE")}
                                     inputValue={props.chapter.title === null ? "" : props.chapter.title}/>
            </div>
        </div>
    );
}

ChapterEditingPage.propTypes = {
    deleteChapterHandler: PropTypes.func.isRequired,
    updateChapterHandler: PropTypes.func.isRequired,

    chapter: PropTypes.object,
    ordinanceAdministrationTitle: PropTypes.string.isRequired,
}

export default ChapterEditingPage;