import {
    ADD_PROFESSION, ADD_PROFESSION_TOPIC,
    ADD_PROFESSION_TOPIC_SECTION,
    DELETE_PROFESSION,
    DELETE_PROFESSION_TOPIC,
    DELETE_PROFESSION_TOPIC_SECTION,
    SET_PROFESSIONS_LIST,
    UPDATE_PROFESSION, UPDATE_PROFESSION_TOPIC,
    UPDATE_PROFESSION_TOPIC_SECTION
} from "../actions/types/ActionTypes";

const defaultState = {
    professions: []
};

const ProfessionsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_PROFESSIONS_LIST: {
            return {
                professions: action.professions.map(profession => {
                    return {
                        id: profession.id,
                        title: profession.title ? profession.title : "",
                        topics: profession.topics.map(topic => {
                            return {
                                id: topic.id,
                                title: topic.title ? topic.title : "",
                                topicSections: topic.topicSections.map(section => {
                                    return {
                                        id: section.id,
                                        title: section.title ? section.title : "",
                                        ordinanceId: section.ordinanceId,
                                        ordinanceTitle: section.ordinanceTitle,
                                        chapterId: section.chapterId,
                                        chapterTitle: section.chapterTitle
                                    }
                                })
                            }
                        })
                    }
                })
            }
        }
        case ADD_PROFESSION: {
            return {
                professions: [...state.professions, {
                    id: action.id,
                    title: action.title ? action.title : "",
                    topics: action.topics
                }]
            }
        }
        case UPDATE_PROFESSION: {
            state.professions.forEach(prof => {
                if (prof.id === action.id) {
                    prof.title = action.title ? action.title : "";
                    prof.topics = action.topics;
                }
            });

            return state;
        }
        case DELETE_PROFESSION: {
            const deletedProfession = state.professions.find(prof => prof.id === action.id);
            state.professions.splice(state.professions.indexOf(deletedProfession), 1);

            return state;
        }
        case ADD_PROFESSION_TOPIC: {

            debugger;
            const foundProfession = state.professions.find(prof => prof.id === action.professionId);

            foundProfession.topics = [...foundProfession.topics, {
                id: action.id,
                title: action.title ? action.title : "",
                topicSections: action.topicSections
            }];

            return state;
        }
        case UPDATE_PROFESSION_TOPIC: {
            const foundProfession = state.professions.find(prof => prof.id === action.professionId);

            foundProfession.topics.forEach(topic => {
                if (topic.id === action.id) {
                    topic.title = action.title ? action.title : "";
                    topic.topicSections = action.topicSections;
                }
            });

            return state;
        }
        case DELETE_PROFESSION_TOPIC: {
            const foundProfession = state.professions.find(prof => prof.id === action.professionId);
            const deletedTopic = foundProfession.topics.find(topic => topic.id === action.id);

            foundProfession.topics.splice(foundProfession.topics.indexOf(deletedTopic), 1);

            return state;
        }
        case ADD_PROFESSION_TOPIC_SECTION: {
            const foundProfession = state.professions.find(prof => prof.id === action.professionId);
            const foundTopic = foundProfession.topics.find(topic => topic.id === action.topicId);

            foundTopic.topicSections = [...foundTopic.topicSections, {
                id: action.id,
                title: action.title ? action.title : "",
                ordinanceId: action.ordinanceId,
                ordinanceTitle: action.ordinanceTitle,
                chapterId: action.chapterId,
                chapterTitle: action.chapterTitle
            }];

            return state;
        }
        case UPDATE_PROFESSION_TOPIC_SECTION: {
            const foundProfession = state.professions.find(prof => prof.id === action.professionId);
            const foundTopic = foundProfession.topics.find(topic => topic.id === action.topicId);

            foundTopic.topicSections.forEach(section => {
                if (section.id === action.id) {
                    section.title = action.title ? action.title : "";
                    section.ordinanceId = action.ordinanceId;
                    section.ordinanceTitle = action.ordinanceTitle;
                    section.chapterId = action.chapterId;
                    section.chapterTitle = action.chapterTitle;
                }
            })

            return state;
        }
        case DELETE_PROFESSION_TOPIC_SECTION: {
            const foundProfession = state.professions.find(prof => prof.id === action.professionId);
            const foundTopic = foundProfession.topics.find(topic => topic.id === action.topicId);
            const deletedSection = foundTopic.topicSections.find(section => section.id === action.id);

            foundTopic.topicSections.splice(foundTopic.topicSections.indexOf(deletedSection), 1);

            return state;
        }
        default: {
            return state;
        }
    }
}

export default ProfessionsReducer;