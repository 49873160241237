import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Checkbox, withStyles} from "@material-ui/core";
import styleClasses from "./UserCheckbox.module.css";

const CheckboxSelection = withStyles({
    root: {
        '&$checked': {
            color: 'var(--color-yellow-hover)',
        }
    },
    checked: {},
})(Checkbox);

function UserCheckbox(props) {

    const handleCheck = () => {
        props.handleCheck(!props.checked, props.user.id)
    }

    return (
        <div className={props.user.blocked ? styleClasses.blockedCheckboxArea : styleClasses.checkboxArea}>
            {props.showCheckbox ? <CheckboxSelection checked={props.checked}
                                                     onChange={handleCheck}/> : <></>}
            <div>
                <h4>{props.user.name}</h4>
                <div>{props.user.expireDate ? new Date(props.user.expireDate).toLocaleDateString() : "No expiration date"} {props.user.blocked ? "- Blocked" : ""}</div>
            </div>
        </div>
    );
}

UserCheckbox.propTypes = {
    showCheckbox: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,

    checked: PropTypes.bool,
    handleCheck: PropTypes.func
};

export default UserCheckbox;