import {
    GET_QUESTION_IMPORTS,
    UPLOAD_QUESTIONS_DOCUMENT,
    START_IMPORT_QUESTIONS,
    SNACKBAR_SUCCESS_APPEARED
} from "./types/ActionTypes"

import api from "../infrastructure/Api";

export const GetQuestionImports = () => async dispatch => {
    const result = await api.getImports();

    dispatch({
        type: GET_QUESTION_IMPORTS,
        imports: result.imports
    });

    return result.imports;
}

export const UploadImportDocument = (preparationFile, title) => async dispatch => {
    await api.uploadQuestionsImportDocument({preparationFile, title});

    await dispatch({
        type: SNACKBAR_SUCCESS_APPEARED,
        messages: ["Document successfully uploaded"],
    });
}

export const StartImportDocument = (importId) => async dispatch => {
    await api.startImport({importId});

    await dispatch({
        type: SNACKBAR_SUCCESS_APPEARED,
        messages: ["Import started"],
    });
}