import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TimelineIcon from '@material-ui/icons/Timeline';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import VerticalAlignTopRoundedIcon from '@material-ui/icons/VerticalAlignTopRounded';
import StyledTreeItem from "../StylesTreeItem/StyledTreeItem";
import GavelRoundedIcon from '@material-ui/icons/GavelRounded';
import {
    ORDINANCES_ADMINISTRATION_PAGE,
    PROFESSIONS_ADMINISTRATION_PAGE,
    IMPORTS_ADMINISTRATION_PAGE
} from "../../utils/routeNames";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    root: {
        marginRight: "25px"
    },
});

export default function AdministrationMenu() {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ArrowDropDownIcon/>}
            defaultExpandIcon={<ArrowRightIcon/>}>
            <StyledTreeItem nodeId="1" labelText={t("ADMINISTRATION_MENU_ITEM_CONSTANTS")} labelIcon={TimelineIcon}>
                <StyledTreeItem
                    nodeId="2"
                    labelText={t("ADMINISTRATION_MENU_ITEM_ORDINANCE")}
                    historyDirection={ORDINANCES_ADMINISTRATION_PAGE}
                    labelIcon={GavelRoundedIcon}/>
                <StyledTreeItem
                    nodeId="3"
                    labelText={t("ADMINISTRATION_MENU_ITEM_PROFESSIONS")}
                    historyDirection={PROFESSIONS_ADMINISTRATION_PAGE}
                    labelIcon={SupervisorAccountIcon}/>
            </StyledTreeItem>
            <StyledTreeItem nodeId="4" labelIcon={ImportExportIcon} labelText={t("ADMINISTRATION_MENU_ITEM_IMPORT_EXPORT")}>
                <StyledTreeItem
                    nodeId="5"
                    labelText={t("ADMINISTRATION_MENU_ITEM_QUESTION_IMPORTS")}
                    historyDirection={IMPORTS_ADMINISTRATION_PAGE}
                    labelIcon={VerticalAlignTopRoundedIcon}/>
            </StyledTreeItem>
        </TreeView>
    );
}