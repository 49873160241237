import classes from './CustomButton.module.css'
import React from 'react';

const CustomButton = (props) => {
  return (
    <button {...props} className={classes.customButton}>
      {props.children}
    </button>
  );
};

export default CustomButton;