import QuestionManagementPage from "./Management/QuestionManagementPage";
import QuestionsTable from "./Table/QuestionsTable";

import {QUESTIONS_TAB_PAGE,
        QUESTIONS_MANAGEMENT_ROUTE_PAGE} from "../../../utils/routeNames";

export default [
    ManageApplicationRoutes(QUESTIONS_TAB_PAGE, QuestionsTable, true),
    ManageApplicationRoutes(QUESTIONS_MANAGEMENT_ROUTE_PAGE, QuestionManagementPage, true),
];

function ManageApplicationRoutes(path, component, isExact) {
    return {
        path,
        component,
        isExact
    };
}