import * as actions from "../actions/types/ActionTypes";

const defaultState = {
    answers: []
};

const AnswersReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.SET_ANSWERS_LIST: {
            return {
                answers: action.data.answers
            };
        }
        case actions.ADD_ANSWER: {
            return {
                answers: [...state.answers, {
                    id: action.id,
                    text: action.text,
                    isCorrect: action.isCorrect
                }]
            };
        }
        case actions.UPDATE_ANSWER: {
            state.answers.forEach(answer => {
                if(answer.id === action.id) {
                    answer.text = action.text;
                    answer.isCorrect = action.isCorrect;
                }
            });

            return state;
        }
        case actions.DELETE_ANSWER: {
            const deletedElement = state.answers.find(answer => answer.id === action.id);
            state.answers.splice(0, 1, state.answers.indexOf(deletedElement));

            return state;
        }
        case actions.RESET_ANSWERS_LIST: {
            return defaultState;
        }
        default: {
            return state;
        }
    }
}

export default AnswersReducer;