import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";

import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";

import MainContainer from "../Containers/MainContainer/MainContainer";
import HeadContainer from "../Containers/HeadContainer/HeadContainer";
import ManagementPageHeader from "../ManagementPageHeader/ManagementPageHeader";
import styleClasses from "./PageManager.module.css";

import {NEW_PAGE_STATUS, UPDATING_PAGE_STATUS, VIEW_PAGE_STATUS} from "../../utils/constants";
import {useTranslation} from "react-i18next";

export default function PageManager(props) {
    const history = useHistory();
    const pageManageState = useSelector(state => state.PageManagementReduce);

    const {t} = useTranslation();

    let leftSideButtonClickHandler = undefined;
    let rightSideButtonClickHandler = undefined;

    let leftSideButtonText = "";
    let rightSideButtonText = "";
    let rightSideButtonIcon = undefined;
    let rightSideButtonAction = "button";

    switch (pageManageState.currentStatus) {
        case NEW_PAGE_STATUS: {
            leftSideButtonClickHandler = async () => {
                await props.actionOnCancelCreation()
                history.goBack();
            }

            leftSideButtonText = t("PAGE_MNGMT_CANCEL_CREATION");
            rightSideButtonText = t("PAGE_MNGMT_SAVE")
            rightSideButtonIcon = <SaveIcon/>;
            rightSideButtonAction = "submit";

            break;
        }
        case UPDATING_PAGE_STATUS: {
            leftSideButtonClickHandler = () => {
                props.actionOnCancelUpdate();
                rightSideButtonAction = "button"
            }

            leftSideButtonText = t("PAGE_MNGMT_CANCEL_EDITING");
            rightSideButtonText = t("PAGE_MNGMT_SAVE")
            rightSideButtonIcon = <SaveIcon/>;
            rightSideButtonAction = "submit";

            break;
        }
        case VIEW_PAGE_STATUS: {
            leftSideButtonClickHandler = () => {
                history.goBack();
            }

            rightSideButtonClickHandler = () => {
                props.actionOnApplyUpdating();
            }

            leftSideButtonText = t("PAGE_MNGMT_BACK");
            rightSideButtonText = t("PAGE_MNGMT_EDIT");
            rightSideButtonIcon = <EditIcon/>;
            rightSideButtonAction = "button";

            break;
        }
    }

    return (
        <div className={styleClasses.containerBg}>
            <HeadContainer>
                <div className={styleClasses.header}>
                    <ManagementPageHeader leftSideButtonText={leftSideButtonText}
                                          handleLeftSideButtonClick={leftSideButtonClickHandler}
                                          rightSideButtonText={rightSideButtonText}
                                          rightSideButtonIcon={rightSideButtonIcon}
                                          handleRightSideButtonClick={rightSideButtonClickHandler}
                                          rightSideButtonType={rightSideButtonAction}/>
                </div>
            </HeadContainer>
            <MainContainer>
                {props.children}
            </MainContainer>
        </div>
    );
}

PageManager.propTypes = {
    actionOnCancelCreation: PropTypes.func,
    actionOnCancelUpdate: PropTypes.func,
    actionOnApplyUpdating: PropTypes.func,

    children: PropTypes.elementType.isRequired
}