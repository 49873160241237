import React from "react";
import PropTypes from "prop-types";
import {useStyles} from "./styles";
import styleClasses from "./TopicSectionEditingPage.module.css";
import AdministrationBreadcrumb from "../../../../../components/AdministrationBreadcrumb/AdministrationBreadcrumb";
import TopicSectionManagementComponent
    from "../../../../../components/TopicSectionManagementComponent/TopicSectionManagementComponent";
import {IconButton} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";


function TopicSectionEditingPage(props) {
    const breadCrumbs = [props.profession.title, props.topic.title];
    const classes = useStyles();

    const updateTopicSectionHandler = (values) => {
        debugger;
        props.updateTopicSectionHandler(props.profession.id, props.topic.id, props.topicSection.id,
            values.title, values.chapter);
    }

    const deleteTopicSectionHandler = () => {
        props.deleteTopicSectionHandler(props.profession.id, props.topic.id, props.topicSection.id);
    }

    const submitActionButton =
        <IconButton className={classes.submitButton}
                                         type="submit">
        <SaveIcon fontSize="large"/>
    </IconButton>;

    return (
        <div className={styleClasses.rootArea}>
            <AdministrationBreadcrumb breadCrumbs={breadCrumbs}
                                      deleteParentElementHandler={deleteTopicSectionHandler}/>
            <div className={styleClasses.managingArea}>
                <TopicSectionManagementComponent isEditable={true}
                                                 buttonElement={submitActionButton }
                                                 formSubmitHandler={updateTopicSectionHandler}
                                                 topicSection={props.topicSection}/>
            </div>
        </div>
    );
}

TopicSectionEditingPage.propTypes = {
    deleteTopicSectionHandler: PropTypes.func.isRequired,
    updateTopicSectionHandler: PropTypes.func.isRequired,

    profession: PropTypes.object.isRequired,
    topic: PropTypes.object.isRequired,

    topicSection: PropTypes.object.isRequired
};

export default TopicSectionEditingPage;