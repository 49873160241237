import React from "react";
import PropTypes from "prop-types";
import styleClasses from "../ChapterEditingPage/ChapterEditingPage.module.css";
import AdministrationBreadcrumb from "../../../../../components/AdministrationBreadcrumb/AdministrationBreadcrumb";
import AdministrationInput from "../../../../../components/AdministrationInput/AdministrationInput";
import {useStyles} from "../ChapterEditingPage/styles";
import {useTranslation} from "react-i18next";

function OrdinanceEditingPage(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const breadCrumbs = [t("ADMINISTRATION_MENU_ITEM_ORDINANCE")];

    const changeHeaderText = (value) => {
        props.updateOrdinanceHandler(props.ordinance.id, props.ordinance.title, value);
    }

    const changeTitleText = (value) => {
        props.updateOrdinanceHandler(props.ordinance.id, value, props.ordinance.header);
    }

    const deleteOrdinanceHandler = () => {
        props.deleteOrdinanceHandler(props.ordinance.id)
    }

    return (
        <div className={styleClasses.rootArea}>
            <AdministrationBreadcrumb breadCrumbs={breadCrumbs}
                                      deleteParentElementHandler={deleteOrdinanceHandler}/>
            <div className={styleClasses.managingArea}>
                <AdministrationInput changeHandler={changeHeaderText}
                                     inputTitle={t("ADMINISTRATION_ORDINANCES_EDIT_INPUT_HEADER")}
                                     className={classes.elementPosition}
                                     inputValue={props.ordinance.header === null ? "" : props.ordinance.header}/>
                <AdministrationInput changeHandler={changeTitleText}
                                     className={classes.elementPosition}
                                     inputTitle={t("ADMINISTRATION_ORDINANCES_EDIT_INPUT_ADMINTITLE")}
                                     inputValue={props.ordinance.title === null ? "" : props.ordinance.title}/>
            </div>
        </div>
    );
}

OrdinanceEditingPage.propTypes = {
    deleteOrdinanceHandler: PropTypes.func.isRequired,
    updateOrdinanceHandler: PropTypes.func.isRequired,

    ordinance: PropTypes.object
}

export default OrdinanceEditingPage;