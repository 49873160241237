import React from "react";
import {InputAdornment, TextField} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import {useStyles} from "./styles";
import {useTranslation} from "react-i18next";

export default function SearchField({value, onChange}) {
    const styles = useStyles();

    const {t, i18n} = useTranslation();

    return (
        <TextField variant="outlined"
                   className={styles.root}
                   size="small"
                   placeholder={t("TABLE_SEARCH_PLACEHOLDER")}
                   onChange={onChange}
                   value={value}
                       InputProps={{
                           startAdornment: (
                               <InputAdornment position="start">
                                   <Search style={{color: "#14293580"}}/>
                               </InputAdornment>
                           )
                       }}
        />
    );
}