import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    elementPosition: {
        marginTop: "10px"
    },
    submitButton: {
        'color': "var(--color-yellow)",
        padding: '0',
        '&:hover': {
            'color': "var(--color-yellow-hover)",
            background: "transparent",
        },
    }
}));