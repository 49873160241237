import React, {useEffect, useState} from "react";
import MainContainer from "../../../../components/Containers/MainContainer/MainContainer";
import HeadContainer from "../../../../components/Containers/HeadContainer/HeadContainer";
import styleClasses from "./OrdinancesAdministration.module.css";
import OrdinancesTreeViewData from "../../../../components/OrdinancesTreeViewData/OrdinancesTreeViewData";
import {useDispatch, useSelector} from "react-redux";
import {requestHandler} from "../../../../infrastructure/requestHandler";
import {CreateOrdinance, DeleteOrdinance, GetOrdinances, UpdateOrdinance} from "../../../../actions/OrdinanceActions";
import AdministratingLeftSidebar
    from "../../../../components/Containers/AdministratingLeftSidebar/AdministratingLeftSidebar";
import AdministratingRightSidebar
    from "../../../../components/Containers/AdministratingRightSidebar/AdministratingRightSidebar";
import NoAdministrationSelectedElement
    from "../../../../components/NoAdministrationSelectedElement/NoAdministrationSelectedElement";
import ChapterEditingPage from "./ChapterEditingPage/ChapterEditingPage";
import {CreateChapter, DeleteChapter, UpdateChapter} from "../../../../actions/ChapterActions";
import OrdinanceEditingPage from "./OrdinanceEditingPage/OrdinanceEditingPage";
import AdministrationHeader from "../../../../components/AdministrationHeader/AdministrationHeader";
import {Add} from "@material-ui/icons";
import {useTranslation} from "react-i18next";

export default function OrdinancesAdministration() {

    const [currentComponentSelection, setCurrentComponentSelection] = useState(<NoAdministrationSelectedElement/>);
    const [currentState, setState] = useState(new Date());

    const ordinanceState = useSelector(state => state.OrdinancesReduce);
    const dispatch = useDispatch();

    const {t} = useTranslation();

    useEffect(async () => {
        await requestHandler(GetOrdinances(), dispatch);
        setState(new Date());
    }, [])

    const updateOrdinanceHandler = async (id, title, header) => {
        await requestHandler(UpdateOrdinance(id, title, header), dispatch);
        setState(new Date());
    }

    const deleteOrdinanceHandler = async (id) => {
        await requestHandler(DeleteOrdinance(id), dispatch);
        setState(new Date());
    }

    const selectOrdinanceHandler = (ordinanceId) => {
        const selectedOrdinance = ordinanceState.ordinances.find(ordinance => ordinance.id === ordinanceId);

        const chapterComponent = <OrdinanceEditingPage
            deleteOrdinanceHandler={deleteOrdinanceHandler}
            updateOrdinanceHandler={updateOrdinanceHandler}
            ordinance={selectedOrdinance}/>

        setCurrentComponentSelection(chapterComponent);
    };

    const updateChapterHandler = async (id, title, header) => {
        await requestHandler(UpdateChapter(id, title, header), dispatch);
        setState(new Date());
    }

    const deleteChapterHandler = async (id) => {
        await requestHandler(DeleteChapter(id), dispatch);
        setState(new Date());
    }

    const selectChapterHandler = (chapterId, ordinanceId) => {
        const selectedOrdinance = ordinanceState.ordinances.find(ordinance => ordinance.id === ordinanceId);
        const selectedChapter = selectedOrdinance.chapters.find(chapter => chapter.id === chapterId)

        const chapterComponent = <ChapterEditingPage
            deleteChapterHandler={deleteChapterHandler}
            updateChapterHandler={updateChapterHandler}
            ordinanceAdministrationTitle={selectedOrdinance.title}
            chapter={selectedChapter}/>

        setCurrentComponentSelection(chapterComponent);
    }

    const createChapterHandler = async (e, data) => {
        await requestHandler(CreateChapter(data.ordinanceId), dispatch);
        setState(new Date());
    }

    const createOrdinanceHandler = async () => {
        await requestHandler(CreateOrdinance(), dispatch)
        setState(new Date());
    }

    return (
        <div className={styleClasses.containerBg}>
            <HeadContainer>
                <AdministrationHeader
                    header={t("ADMINISTRATION_MENU_ITEM_ORDINANCE")}
                    buttonText={t("ADMINISTRATION_CONSTANTS_ORDINANCES_BTN")}
                    buttonIcon={<Add/>}
                    buttonClickHandler={createOrdinanceHandler}/>
            </HeadContainer>
            <MainContainer>
                <div className={styleClasses.dataContainer}>
                    <AdministratingLeftSidebar>
                        <OrdinancesTreeViewData ordinances={ordinanceState.ordinances}
                                                createChapterHandler={createChapterHandler}
                                                selectOrdinanceHandler={selectOrdinanceHandler}
                                                selectChapterHandler={selectChapterHandler}/>
                    </AdministratingLeftSidebar>
                    <AdministratingRightSidebar>
                        {currentComponentSelection}
                    </AdministratingRightSidebar>
                </div>
            </MainContainer>
        </div>
    );
}