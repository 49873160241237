import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {Button, InputLabel, MenuItem, Select, TextField, Tooltip} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import * as yup from 'yup';
import styleClasses from "./QuestionManagementPage.module.css";
import {useStyles} from "./styles";
import noImage from "../../../../static/no_image_exist.png";

import {
    VIEW_PAGE_STATUS
} from "../../../../utils/constants"
import {requestHandler} from "../../../../infrastructure/requestHandler";
import {
    DeleteQuestion,
    UpdateQuestion,
    ViewQuestion,
    GetQuestionData,
    UpdateQuestionData
} from "../../../../actions/QuestionActions";
import {GetOrdinances, GetOrdinance} from "../../../../actions/OrdinanceActions";
import PageManager from "../../../../components/PageManager/PageManager";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import {GetImage} from "../../../../actions/ImageActions";
import AnswersManagementModal from "../../../../components/AnswersManagementModal/AnswersManagementModal";
import {ResetAnswersList} from "../../../../actions/AnswerActions";
import {useTranslation} from "react-i18next";

function QuestionSelectElement({
                                   name, label, disabled, value,
                                   onChangeHandler, touchedElement, errorElement, selectionList,
                                   handleTooltipTitleDisplaying
                               }) {
    return (
        <>
            <InputLabel shrink id={`${name}-select-label`}>{label}</InputLabel>
            <Select
                name={`${name}`}
                disabled={disabled}
                value={value}
                onChange={onChangeHandler}
                error={touchedElement && Boolean(errorElement)}
                helperText={touchedElement && errorElement}
                labelId={`${name}-select-label`}
                id={`${name}Selection`}
                label={label}
                style={{
                    maxWidth: '25vw'
                }}>
                {selectionList.map(selection => {
                    if (value === selection.key) {
                        handleTooltipTitleDisplaying(selection.value);
                    }
                    return <MenuItem value={selection.key}>{selection.value}</MenuItem>
                })}
            </Select>
        </>
    )
}

function QuestionTextareaElement({
                                     name, label, disabled, value, onChangeHandler,
                                     touchedElement, errorElement, styles, minRows
                                 }) {
    return (
        <>
            <TextField
                name={name}
                disabled={disabled}
                value={value}
                onChange={onChangeHandler}
                error={touchedElement && Boolean(errorElement)}
                helperText={touchedElement && errorElement}
                className={styles.textField}
                minRows={minRows}
                label={label}
                InputLabelProps={{
                    shrink: true,
                }}
                multiline
                variant="outlined"/>
        </>);
}

export default function QuestionManagementPage(props) {

    const styles = useStyles();

    const questionId = props.match.params.id;
    const dispatch = useDispatch();

    const pageManageState = useSelector(state => state.PageManagementReduce);
    const loading = useSelector(state => state.LoadingReduce);

    const {t} = useTranslation();

    const [question, setQuestion] = useState(undefined);
    const [isEditingDisabled, disableEditing] = useState(pageManageState.currentStatus === VIEW_PAGE_STATUS);
    const [ordinances, setOrdinances] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [image, setImage] = useState(undefined);
    const [displayImage, setDisplayImage] = useState(undefined);
    const [imagePath, setImagePath] = useState(null);

    const [selectedOrdinanceTooltip, setSelectedOrdinanceTooltip] = useState(undefined)
    const [selectedChapterTooltip, setSelectedChapterTooltip] = useState(undefined)

    useEffect(async () => {
        let question = await requestHandler(GetQuestionData(questionId), dispatch);
        setQuestion(question);

        const result = await requestHandler(GetOrdinances(), dispatch);

        let ordinancesArr = [];
        result.ordinances.forEach(ordinance => {
            ordinancesArr.push({
                key: ordinance.id,
                value: ordinance.title
            });
        });

        if (question.ordinance !== null) {
            await setupChaptersList(question.ordinance.id);
        }

        if (question.imagePath !== null) {
            let imageResult = await GetImage(question.imagePath);

            const imageBytes = base64ToArrayBuffer(imageResult.imageBase64String);
            var blob = new Blob([imageBytes], {type: imageResult.contentType});

            setDisplayImage(URL.createObjectURL(blob));
            setImagePath(question.imagePath);
        }

        setOrdinances(ordinancesArr);
            dispatch(ResetAnswersList());
    }, [])

    function base64ToArrayBuffer(base64) {
        let binaryString = window.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

    useEffect(() => {
        disableEditing(pageManageState.currentStatus === VIEW_PAGE_STATUS);
    }, [pageManageState.currentStatus]);

    const saveQuestion = async (values) => {
        await requestHandler(UpdateQuestionData(questionId, values, image, imagePath), dispatch);
    };


    const validationSchema = yup.object({
        ordinance: yup.string(t('QUESTIONS_MNGMT_INPUT_ORDINANCE')).required(t('QUESTIONS_MNGMT_INPUT_VALIDATION_ORDINANCE')).nullable(),
        chapter: yup.string(t('QUESTIONS_MNGMT_INPUT_CHAPTER')).required(t('QUESTIONS_MNGMT_INPUT_VALIDATION_CHAPTER')).nullable(),
        text: yup.string(t('QUESTIONS_MNGMT_INPUT_QUESTION')).required(t('QUESTIONS_MNGMT_INPUT_VALIDATION_QUESTION')).nullable(),
        explanation: yup.string(t('QUESTIONS_MNGMT_INPUT_EXPLANATION')).required(t("QUESTIONS_MNGMT_INPUT_VALIDATION_EXPLANATION")).nullable()
    });

    const formik = useFormik({
        onSubmit: saveQuestion,
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            ordinance: question === undefined ? null : question.ordinance === null ? null : question.ordinance.id,
            chapter: question === undefined ? null : question.chapter === null ? null : question.chapter.id,
            text: question === undefined ? null : question.text,
            point: question === undefined ? null : question.point,
            explanation: question === undefined ? null : question.explanation,
            image: question === undefined ? null : question.imagePath
        },
    });

    const handleOrdinanceChoice = async (e) => {
        formik.handleChange(e);

        const ordinanceId = e.target.value;
        await setupChaptersList(ordinanceId);
    }

    const applyImageChoice = (e) => {
        if (e.target.files.length > 0) {
            setImage(e.target.files[0]);
            setDisplayImage(URL.createObjectURL(e.target.files[0]))
        }
    }

    const clearImageArea = () => {
        setDisplayImage(undefined);
        setImage(undefined);
        setImagePath(null);
    }

    const setupChaptersList = async (ordinanceId) => {
        const result = await GetOrdinance(ordinanceId);

        let chapterArr = [];

        result.chapters.forEach(chapter => {
            chapterArr.push({
                key: chapter.id,
                value: chapter.title
            });
        });

        setChapters(chapterArr);
    }

    const actionOnCancelCreation = async () => {
        await requestHandler(DeleteQuestion(questionId), dispatch);
    }

    const actionOnCancelUpdate = () => {
        dispatch(ViewQuestion());
        disableEditing(true);
    }

    const actionOnApplyUpdating = () => {
        disableEditing(false);
        dispatch(UpdateQuestion());
    }

    const handleOrdinanceTooltipSelection = (title) => {
        setSelectedOrdinanceTooltip(title)
    }

    const handleChapterTooltipSelection = (title) => {
        setSelectedChapterTooltip(title)
    }

    const showingForm = (
        <PageManager actionOnCancelCreation={actionOnCancelCreation}
                     actionOnCancelUpdate={actionOnCancelUpdate}
                     actionOnApplyUpdating={actionOnApplyUpdating}>
            <div className={styleClasses.formArea}>
                <div className={styleClasses.leftArea}>
                    <div className={styleClasses.selectElementsArea}>
                        <div>
                            <Tooltip title={selectedOrdinanceTooltip} disableHoverListener={!isEditingDisabled}>
                                <FormControl variant="standard" className={styles.selectElement}>
                                    <QuestionSelectElement
                                        name="ordinance"
                                        value={formik.values.ordinance}
                                        errorElement={formik.errors.ordinance}
                                        touchedElement={formik.touched.ordinance}
                                        label={t("QUESTIONS_MNGMT_INPUT_ORDINANCE_LABEL")}
                                        onChangeHandler={handleOrdinanceChoice}
                                        selectionList={ordinances}
                                        disabled={isEditingDisabled}
                                        handleTooltipTitleDisplaying={handleOrdinanceTooltipSelection}/>
                                </FormControl>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title={selectedChapterTooltip} disableHoverListener={!isEditingDisabled}>
                                <FormControl variant="standard" className={styles.selectElement}>
                                    <QuestionSelectElement
                                        name="chapter"
                                        value={formik.values.chapter}
                                        errorElement={formik.errors.chapter}
                                        touchedElement={formik.touched.chapter}
                                        label={t("QUESTIONS_MNGMT_INPUT_CHAPTER_LABEL")}
                                        onChangeHandler={formik.handleChange}
                                        selectionList={chapters}
                                        disabled={isEditingDisabled}
                                        handleTooltipTitleDisplaying={handleChapterTooltipSelection}/>
                                </FormControl>
                            </Tooltip>
                        </div>
                    </div>

                    <div>
                        <QuestionTextareaElement onChangeHandler={formik.handleChange}
                                                 touchedElement={formik.touched.text}
                                                 errorElement={formik.errors.text}
                                                 value={formik.values.text}
                                                 disabled={isEditingDisabled}
                                                 minRows={4}
                                                 styles={styles}
                                                 label={t("QUESTIONS_MNGMT_INPUT_TEXT_LABEL")}
                                                 name="text"/>
                    </div>
                    <div>
                        <QuestionTextareaElement onChangeHandler={formik.handleChange}
                                                 touchedElement={formik.touched.point}
                                                 errorElement={formik.errors.point}
                                                 value={formik.values.point}
                                                 disabled={isEditingDisabled}
                                                 minRows={3}
                                                 styles={styles}
                                                 label={t("QUESTIONS_MNGMT_INPUT_POINT_LABEL")}
                                                 name="point"/>
                    </div>
                    <div>
                        <QuestionTextareaElement onChangeHandler={formik.handleChange}
                                                 touchedElement={formik.touched.explanation}
                                                 errorElement={formik.errors.explanation}
                                                 value={formik.values.explanation}
                                                 disabled={isEditingDisabled}
                                                 minRows={3}
                                                 styles={styles}
                                                 label={t("QUESTIONS_MNGMT_INPUT_EXPLANATION_LABEL")}
                                                 name="explanation"/>
                    </div>
                    <AnswersManagementModal questionId={questionId}/>
                </div>

                <div className={styleClasses.rightArea}>
                    <div className={styleClasses.photoArea}>
                        <img alt="question's image" className={styleClasses.image}
                             src={displayImage ?? noImage}/>
                    </div>
                    <div className={styles.uploadArea}>
                        <Button variant="outlined"
                                className={styles.uploadImageButton}
                                component="label"
                                disabled={isEditingDisabled}>
                            <input type="file"
                                   name="image"
                                   hidden
                                   onChange={applyImageChoice}/>
                            <AttachFileIcon/> {t("QUESTIONS_MNGMT_IMAGE_ATTACH_BTN")}
                        </Button>
                        <Button disabled={isEditingDisabled}
                                variant="outlined"
                                onClick={clearImageArea}>
                            <DeleteIcon/> {t("QUESTIONS_MNGMT_IMAGE_CLEAR_BTN")}
                        </Button>
                    </div>
                </div>
            </div>
        </PageManager>
    );

    if (isEditingDisabled) {
        return (
            <>
                {loading.isLoading && <LoadingSpinner/>}
                {showingForm}
            </>
        )
    } else {
        return (
            <form aria-disabled={true} onSubmit={formik.handleSubmit}>
                {loading.isLoading && <LoadingSpinner/>}
                {showingForm}
            </form>
        );
    }
}