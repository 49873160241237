import styleClasses from "../../../HomePage.module.css";
import classes from "./ImportsTable.module.css";

import React, {useEffect, useState} from "react";
import {TableCell, Typography} from "@material-ui/core";

import ImportTableActions from "../../../../../components/ImportTableActions/ImportTableActions";
import PreviewTable from "../../../../../components/PreviewTable/PreviewTable";
import ImportStatus from "../../../../../components/ImportStatus/ImportStatus";
import CircularProgressWithLabel from "../../../../../components/CircularProgressWithLabel/CircularProgressWithLabel";
import {useTranslation} from "react-i18next";

function getHeadCellsWithTranslation(t) {
    return [
        {id: "title", align: "left", label: t("ADMINISTRATION_IMPORT_TABLE_HEADER_TITLE"), sortArea: "none"},
        {id: "status", align: "left", label: t("ADMINISTRATION_IMPORT_TABLE_HEADER_STATUS"), sortArea: "none"},
        {id: "createdAt", align: "left", label: t("ADMINISTRATION_IMPORT_TABLE_HEADER_CREATEDAT"), sortArea: "none"},
        {id: "uploadProgress", align: "left", label: t("ADMINISTRATION_IMPORT_TABLE_HEADER_UPLOAD_PROGRESS"), sortArea: "none"},
        {id: "Action", align: "right", label: "", sortArea: "none"}
    ];
}

function convertStringDate(strDate) {
    const date = new Date(strDate);

    return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." +
        date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
}

function prepareTableCells(rowsData, setParentState, parentState, t) {
    let preparedCells = [];

    rowsData.map(row => {
        preparedCells.push([
            <TableCell className={classes.titleCell}><Typography
                className={classes.textArea}>{row.title}</Typography></TableCell>,
            <TableCell className={classes.status}>{ImportStatus({status: row.status, t: t})}</TableCell>,
            <TableCell className={classes.createdAt}><Typography
                className={classes.textArea}>{convertStringDate(row.createdAt)}</Typography></TableCell>,
            <TableCell
                className={classes.processingPercentage}>{row.importProgress === -1 ? <></> : CircularProgressWithLabel({value: row.importProgress})}</TableCell>,
            <TableCell align="right">{<ImportTableActions setParentState={setParentState} status={row.status}
                                                          parentState={parentState}
                                                          importId={row.id}/>}</TableCell>,
        ]);
    })

    return preparedCells;
}

export default function ImportsTable(props) {

    const [stateUpdated, setUpdatingState] = useState(false);
    const {t} = useTranslation();

    return (
        <div className={styleClasses.containerBg}>
            <PreviewTable rowsPerPage={props.importsList.length}
                          isAscending={true}
                          currentPage={0}
                          itemsCount={props.importsList.length}
                          tableHeaderItems={getHeadCellsWithTranslation(t)}
                          hidePagination={true}
                          preparedTableData={prepareTableCells(props.importsList, setUpdatingState, stateUpdated, t)}/>
        </div>
    );
}