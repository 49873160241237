import React from 'react';
import PropTypes from 'prop-types';
import TreeView from "@material-ui/lab/TreeView";
import MinusSquareMenuIcon from "../MinusSquareMenuIcon/MinusSquareMenuIcon";
import PlusSquareMenuIcon from "../PlusSquareMenuIcon/PlusSquareMenuIcon";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import styleClasses from "./ProfessionTreeViewData.module.css";
import {TreeViewItemNode} from "../TreeViewItemNode/TreeViewItemNode";
import {useStyles} from "./styles";
import {useTranslation} from "react-i18next";

function ProfessionTreeViewData(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    let index = 1;

    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<MinusSquareMenuIcon/>}
            defaultExpandIcon={<PlusSquareMenuIcon/>}>
            {props.professions.map((profession) => {
                return (
                    <>
                        <ContextMenuTrigger id={`contextmenu_${profession.id}`}>
                            <TreeViewItemNode nodeId={index++}
                                              onClick={() => props.selectProfessionHandler(profession.id)}
                                              label={profession.title ? profession.title : t("ADMINISTRATION_TREEVIEW_NOT_DEFINED")}>
                                {profession.topics.map((topic) => {
                                    return (<TreeViewItemNode nodeId={index++}
                                                              onClick={() => props.selectTopicHandler(topic.id, profession.id)}
                                                              label={topic.title ? topic.title : t("ADMINISTRATION_TREEVIEW_NOT_DEFINED")}>
                                        {topic.topicSections.map((section) => {
                                            return (<TreeViewItemNode nodeId={index++}
                                                                      onClick={() => props.selectTopicSectionHandler(profession.id, topic.id, section.id)}
                                                                      label={section.title ? section.title : t("ADMINISTRATION_TREEVIEW_NOT_DEFINED")}
                                            />)
                                        })}
                                    </TreeViewItemNode>);
                                })}
                            </TreeViewItemNode>
                        </ContextMenuTrigger>

                        <ContextMenu id={`contextmenu_${profession.id}`}
                                     className={styleClasses.contextMenu}>
                            <MenuItem data={{professionId: profession.id}}
                                      onClick={props.createTopicHandler}
                                      className={styleClasses.contextMenuElement}>
                                {t("ADMINISTRATION_PROFESSIONS_TREE_VIEW_CONTEXT")}
                            </MenuItem>
                        </ContextMenu>
                    </>
                );
            })}
        </TreeView>
    );
}

ProfessionTreeViewData.propTypes = {
    professions: PropTypes.array.isRequired,
    createTopicHandler: PropTypes.func.isRequired,
    selectProfessionHandler: PropTypes.func.isRequired,
    selectTopicHandler: PropTypes.func.isRequired,
    selectTopicSectionHandler: PropTypes.func.isRequired
};

export default ProfessionTreeViewData;