import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles({
    '@global': {
        ".MuiSwitch-colorSecondary.Mui-checked": {
            color: "var(--color-yellow)"
        },
        ".MuiSwitch-colorSecondary.Mui-checked:hover": {
            backgroundColor: "rgb(224, 222, 163, 0.25)"
        },
        ".MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "var(--color-yellow-hover)"
        },
        ".MuiSwitch-track": {
            backgroundColor: "var(--color-yellow-hover)"
        },
        ".MuiSwitch-colorSecondary": {
            color: "var(--color-yellow)"
        }
    },
});