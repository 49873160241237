import PageManager from "../../../../components/PageManager/PageManager";
import styleClasses from "./UserManagementPage.module.css";
import React, {useEffect, useState} from "react";
import {requestHandler} from "../../../../infrastructure/requestHandler";
import {
    DeleteUser,
    GetUserData,
    UpdateUser,
    UpdateUserCredentials,
    UpdateUserData, UpdateUserExpiration, UpdateUserProfessions,
    ViewUser
} from "../../../../actions/UserActions";
import {useDispatch, useSelector} from "react-redux";
import {VIEW_PAGE_STATUS} from "../../../../utils/constants";
import {GetDropdownCompanies} from "../../../../actions/CompanyActions";
import * as yup from "yup";
import {useFormik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormSelectionElement from "../../../../components/FormSelectionElement/FormSelectionElement";
import FormTextareaElement from "../../../../components/FormTextareaElement/FormTextareaElement";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import {useStyles} from "./styles";
import PasswordChangeModal from "../../../../components/PasswordChangeModal/PasswordChangeModal";
import ExpirationDatePicker from "../../../../components/ExpirationDatePicker/ExpirationDatePicker";
import ProfessionsPickerModal from "../../../../components/ProfessionsPickerModal/ProfessionsPickerModal";
import {useTranslation} from "react-i18next";

const rolesList = [
    {
        key: "ADMIN",
        value: "ADMIN"
    },
    {
        key: "OBSERVER",
        value: "OBSERVER"
    },
    {
        key: "USER",
        value: "USER"
    }];

export default function UserManagementPage(props) {

    const userId = props.match.params.id;
    const dispatch = useDispatch();

    const styles = useStyles();
    const pageManageState = useSelector(state => state.PageManagementReduce);
    const loading = useSelector(state => state.LoadingReduce);

    const {t} = useTranslation();

    const [isEditingDisabled, disableEditing] = useState(pageManageState.currentStatus === VIEW_PAGE_STATUS);
    const [user, setUser] = useState(undefined);
    const [companies, setCompanies] = useState([]);
    const [credentialsModalState, updateCredentialsModalState] = useState(false);

    useEffect(async () => {
        let user = await requestHandler(GetUserData(userId), dispatch);
        setUser(user);

        const result = await requestHandler(GetDropdownCompanies(), dispatch);
        const companiesList = [];
        companiesList.push({
            key: '',
            value: "None"
        });

        result.pagedElements.forEach(company => {
            companiesList.push({
                key: company.id,
                value: company.title
            });
        });

        setCompanies(companiesList);
    }, []);

    useEffect(() => {
        disableEditing(pageManageState.currentStatus === VIEW_PAGE_STATUS);
    }, [pageManageState.currentStatus]);

    const saveUser = async (values) => {
        const {name, email, role, company} = values;
        await requestHandler(UpdateUserData(userId, name, email, role, company), dispatch);
    }

    const validationSchema = yup.object({
        role: yup.string(t("USERS_MNGMT_INPUT_ROLE")).required(t('USERS_MNGMT_INPUT_VALIDATION_ROLE')).nullable(),
        name: yup.string(t("USERS_MNGMT_INPUT_NAME")).required(t("USERS_MNGMT_INPUT_VALIDATION_NAME")).nullable(),
        email: yup.string(t("USERS_MNGMT_INPUT_EMAIL")).email(t("USERS_MNGMT_INPUT_VALIDATION_EMAIL")).nullable()
    });

    const formik = useFormik({
        onSubmit: saveUser,
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            name: user === undefined ? null : user.name,
            email: user === undefined ? null : user.email,
            role: user === undefined ? null : user.role,
            company: user === undefined ? null : user.company === null ? null : user.company.id,
        },
    });

    const updateUserCredentialsHandler = async (id, login, password) => {
        const updatedLogin = await requestHandler(UpdateUserCredentials(id, login, password), dispatch);
        setUser({
            ...user,
            login: updatedLogin
        });
    }

    const actionOnCancelCreation = async () => {
        await requestHandler(DeleteUser(userId), dispatch);
    }

    const actionOnCancelUpdate = () => {
        dispatch(ViewUser());
        disableEditing(true);
    }

    const actionOnApplyUpdating = () => {
        disableEditing(false);
        dispatch(UpdateUser());
    }

    const handleExpirationUpdate = async (selectedDate, selectedUsers) => {
        const updatedUser = await requestHandler(UpdateUserExpiration(selectedUsers[0], selectedDate), dispatch);

        setUser({
            ...user,
            expireDate: updatedUser.expireDate
        });
    }

    const handleSavingUserProfessions = async (userProfessions) => {
        const professionsIds = userProfessions.map((prof) => {
            return prof.id
        });

        const updatedProfessionsList = await requestHandler(UpdateUserProfessions(user.id, professionsIds), dispatch);
        setUser({
            ...user,
            professions: updatedProfessionsList
        });
    }

    const showingForm = (
        <PageManager actionOnCancelCreation={actionOnCancelCreation}
                     actionOnCancelUpdate={actionOnCancelUpdate}
                     actionOnApplyUpdating={actionOnApplyUpdating}>
            <div className={styleClasses.formArea}>
                <div className={styleClasses.dependentArea}>

                    <div>
                        <FormTextareaElement onChangeHandler={formik.handleChange}
                                             touchedElement={formik.touched.name}
                                             errorElement={formik.errors.name}
                                             value={formik.values.name}
                                             disabled={isEditingDisabled}
                                             minRows={1}
                                             styles={styles}
                                             label={t("USERS_MNGMT_INPUT_NAME_LABEL")}
                                             name="name"/>
                    </div>
                    <div>
                        <FormTextareaElement onChangeHandler={formik.handleChange}
                                             touchedElement={formik.touched.email}
                                             errorElement={formik.errors.email}
                                             value={formik.values.email}
                                             disabled={isEditingDisabled}
                                             minRows={1}
                                             styles={styles}
                                             label={t("USERS_MNGMT_INPUT_EMAIL_LABEL")}
                                             name="email"/>
                    </div>
                    <div>
                        <FormControl variant="standard" className={styles.selectElement}>
                            <FormSelectionElement
                                name="role"
                                value={formik.values.role}
                                errorElement={formik.errors.role}
                                touchedElement={formik.touched.role}
                                label={t("USERS_MNGMT_INPUT_ROLE_LABEL")}
                                selectionList={rolesList}
                                onChangeHandler={formik.handleChange}
                                disabled={isEditingDisabled}/>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl variant="standard" className={styles.selectElement}>
                            <FormSelectionElement
                                name="company"
                                value={formik.values.company}
                                errorElement={formik.errors.company}
                                touchedElement={formik.touched.company}
                                label={t("USERS_MNGMT_INPUT_COMPANIES_LABEL")}
                                onChangeHandler={formik.handleChange}
                                selectionList={companies}
                                disabled={isEditingDisabled}/>
                        </FormControl>
                    </div>
                </div>
                <div className={styleClasses.dependentArea}>
                    <PasswordChangeModal userId={userId}
                                         login={user ? user.login ?? "" : ""}
                                         isModalOpened={credentialsModalState}
                                         openModal={updateCredentialsModalState}
                                         updateUserCredentialsHandler={updateUserCredentialsHandler}/>
                    <div className={styleClasses.datePicker}>
                        <ExpirationDatePicker forSingleUserPicker={true}
                                              usersList={[user]}
                                              expireDate={user ? user.expireDate : ""}
                                              handleExpirationUpdate={handleExpirationUpdate}/>
                    </div>
                </div>
                <div className={styleClasses.dependentArea}>
                    <ProfessionsPickerModal userProfessions={user ? user.professions : []}
                                            saveProfessionsListHandler={handleSavingUserProfessions}/>
                </div>
            </div>
        </PageManager>
    );

    if (isEditingDisabled) {
        return (
            <>
                {loading.isLoading && <LoadingSpinner/>}
                {showingForm}
            </>
        )
    } else {
        return (
            <form onSubmit={formik.handleSubmit}>
                {loading.isLoading && <LoadingSpinner/>}
                {showingForm}
            </form>
        );
    }
}