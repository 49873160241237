import styleClasses from "../../HomePage.module.css";
import classes from "./QuestionTable.module.css";

import HeadContainer from "../../../../components/Containers/HeadContainer/HeadContainer";
import MainContainer from "../../../../components/Containers/MainContainer/MainContainer";

import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import PreviewTable from "../../../../components/PreviewTable/PreviewTable";
import TableHeader from "../../../../components/TableHeader/TableHeader";
import {TableCell, Typography} from "@material-ui/core";
import ActiveDot from "../../../../components/ActiveDot/ActiveDot";
import {useDispatch, useSelector} from "react-redux";

import {
    SearchInfoInRows,
    SetChangePage, SetDefaultTableState,
    SetRowsPerPage,
    SortRowsByFields
} from "../../../../actions/TableActions";
import {requestHandler} from "../../../../infrastructure/requestHandler";
import {
    FetchQuestionsTableData,
    CreateNewQuestion
} from "../../../../actions/QuestionActions";
import QuestionTableActions from "../../../../components/QuestionTableActions/QuestionTableActions";
import {QUESTIONS_MANAGEMENT_PAGE} from "../../../../utils/routeNames";
import {useTranslation} from "react-i18next";

function getHeadCellsWithTranslation(t) {
    return [
        {id: "text", align: "left", label: t("QUESTIONS_TABLE_HEADER_TEXT"), sortArea: "ascending"},
        {id: "ordinance", align: "left", label: t("QUESTIONS_TABLE_HEADER_ORDINANCE"), sortArea: "ascending"},
        {id: "chapter", align: "left", label: t("QUESTIONS_TABLE_HEADER_CHAPTER"), sortArea: "ascending"},
        {id: "Point", align: "left", label: t("QUESTIONS_TABLE_HEADER_POINT"), sortArea: "ascending"},
        {id: "ImagePath", align: "center", label: t("QUESTIONS_TABLE_HEADER_IMAGE"), sortArea: "ascending"},
        {id: "Action", align: "right", label: "", sortArea: "none"}
    ];
}

function prepareTableCells(rowsData, setParentState, parentState) {
    let preparedCells = [];

    rowsData.map(row => {
        preparedCells.push([
            <TableCell className={classes.textCell}><Typography
                className={classes.textArea}>{row.text}</Typography></TableCell>,
            <TableCell className={classes.ordinanceCell}><Typography
                className={classes.textArea}>{row.ordinance}</Typography></TableCell>,
            <TableCell className={classes.chapterCell}><Typography
                className={classes.textArea}>{row.chapter}</Typography></TableCell>,
            <TableCell className={classes.pointCell}><Typography
                className={classes.textArea}>{row.point}</Typography></TableCell>,
            <TableCell align="center" className={classes.imageCell}><ActiveDot isActive={row.imageExists}/></TableCell>,
            <TableCell align="right">{<QuestionTableActions setParentState={setParentState} parentState={parentState}
                                                            questionId={row.id}/>}</TableCell>,
        ]);
    })

    return preparedCells;
}

export default function QuestionsTable() {

    const history = useHistory();
    const dispatch = useDispatch();
    const [stateUpdated, setUpdatingState] = useState(false);
    const tableState = useSelector(state => state.TableReduce);

    const {t} = useTranslation();

    useEffect(() => {
        dispatch(SetDefaultTableState());
    }, []);

    useEffect(() => {
        requestHandler(FetchQuestionsTableData(tableState), dispatch);
    }, [stateUpdated])

    const handleSortAction = (orderBy, isAsc) => {
        dispatch(SortRowsByFields(orderBy, isAsc));
        setUpdatingState(!stateUpdated);
    }

    const handleChangePage = (event, newPage) => {
        dispatch(SetChangePage(newPage));
        setUpdatingState(!stateUpdated);
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(SetRowsPerPage(parseInt(event.target.value, 10)));
        setUpdatingState(!stateUpdated);
    };

    const handleSearchInput = ({value}) => {
        dispatch(SearchInfoInRows(value));
        setUpdatingState(!stateUpdated);
    }

    const handleButtonClick = async () => {
        var result = await requestHandler(CreateNewQuestion(), dispatch)

        history.push(QUESTIONS_MANAGEMENT_PAGE + `/${result.id}`);
    }

    return (
        <div className={styleClasses.containerBg}>
            <HeadContainer>
                <div className={classes.header}>
                    <TableHeader buttonText={t("QUESTIONS_TABLE_BUTTON")}
                                 handleButtonClick={handleButtonClick}
                                 handleSearchInput={handleSearchInput}/>
                </div>
            </HeadContainer>
            <MainContainer>
                <PreviewTable rowsPerPage={tableState.takeRows}
                              orderBy={tableState.sortField}
                              isAscending={tableState.sortAscending}
                              handleSkippingRows={handleChangePage}
                              handleSort={handleSortAction}
                              currentPage={tableState.currentPage}
                              itemsCount={tableState.tableTotalDataCount}
                              tableHeaderItems={getHeadCellsWithTranslation(t)}
                              preparedTableData={prepareTableCells(tableState.tableData, setUpdatingState, stateUpdated)}
                              handleTakingRows={handleChangeRowsPerPage}/>
            </MainContainer>
        </div>
    );
}