import {useDispatch, useSelector} from "react-redux";
import {useStyles} from "../../Users/Management/styles";
import React, {useEffect, useState} from "react";
import {VIEW_PAGE_STATUS} from "../../../../utils/constants";
import {requestHandler} from "../../../../infrastructure/requestHandler";
import {
    DeleteCompany,
    GetCompanyData,
    UpdateCompany, UpdateCompanyData, UpdateCompanyProfessions, UpdateCompanyUsersExpirationDates,
    ViewCompany
} from "../../../../actions/CompanyActions";
import * as yup from "yup";
import {useFormik} from "formik";
import PageManager from "../../../../components/PageManager/PageManager";
import styleClasses from "../../Users/Management/UserManagementPage.module.css";
import ExpirationDatePicker from "../../../../components/ExpirationDatePicker/ExpirationDatePicker";
import ProfessionsPickerModal from "../../../../components/ProfessionsPickerModal/ProfessionsPickerModal";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import FormTextFieldElement from "../../../../components/FormTextFieldElement/FormTextFieldElement";
import {useTranslation} from "react-i18next";

export default function CompanyManagementPage(props) {

    const companyId = props.match.params.id;
    const dispatch = useDispatch();

    const styles = useStyles();
    const pageManageState = useSelector(state => state.PageManagementReduce);
    const loading = useSelector(state => state.LoadingReduce);

    const [isEditingDisabled, disableEditing] = useState(pageManageState.currentStatus === VIEW_PAGE_STATUS);
    const [company, setCompany] = useState(undefined);

    const {t} = useTranslation();

    useEffect(async () => {
        let company = await requestHandler(GetCompanyData(companyId), dispatch);
        setCompany(company);
    }, []);

    useEffect(() => {
        disableEditing(pageManageState.currentStatus === VIEW_PAGE_STATUS);
    }, [pageManageState.currentStatus]);

    const saveCompany = async (values) => {
        const {title, usersCapacity, professionsCapacity} = values;
        await requestHandler(UpdateCompanyData(companyId, title, usersCapacity, professionsCapacity), dispatch);
    }

    const validationSchema = yup.object({
        title: yup.string().required(t('COMPANIES_MNGMT_VALIDATION_TITLE')).nullable(),
        usersCapacity: yup.number().required(t("COMPANIES_MNGMT_VALIDATION_USERS_CAPACITY")).nullable(),
        professionsCapacity: yup.number().required(t("COMPANIES_MNGMT_VALIDATION_PROFESSIONS_CAPACITY")).nullable()
    });

    const formik = useFormik({
        onSubmit: saveCompany,
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            title: company === undefined ? null : company.title,
            usersCapacity: company === undefined ? null : company.usersCapacity,
            professionsCapacity: company === undefined ? null : company.professionsCapacity,
        },
    });

    const actionOnCancelCreation = async () => {
        await requestHandler(DeleteCompany(companyId), dispatch);
    }

    const actionOnCancelUpdate = () => {
        dispatch(ViewCompany());
        disableEditing(true);
    }

    const actionOnApplyUpdating = () => {
        disableEditing(false);
        dispatch(UpdateCompany());
    }

    const handleExpirationUpdate = async (selectedDate, selectedUsers) => {
        const updatedCompany = await requestHandler(UpdateCompanyUsersExpirationDates(companyId, selectedUsers, selectedDate), dispatch);

        setCompany({
            ...company,
            users: updatedCompany.users,
            expireDate: updatedCompany.expireDate
        });
    }

    const handleSavingCompanyProfessions = async (companyProfessions) => {
        const professionsIds = companyProfessions.map((prof) => {
            return prof.id
        });

        const updatedProfessionsList = await requestHandler(UpdateCompanyProfessions(companyId, professionsIds), dispatch);

        if (updatedProfessionsList !== undefined) {
            setCompany({
                ...company,
                professions: updatedProfessionsList
            });
        }
    }

    const showingForm = (
        <PageManager actionOnCancelCreation={actionOnCancelCreation}
                     actionOnCancelUpdate={actionOnCancelUpdate}
                     actionOnApplyUpdating={actionOnApplyUpdating}>
            <div className={styleClasses.formArea}>
                <div className={styleClasses.dependentArea}>
                    <div>
                        <FormTextFieldElement onChangeHandler={formik.handleChange}
                                              touchedElement={formik.touched.title}
                                              errorElement={formik.errors.title}
                                              value={formik.values.title}
                                              disabled={isEditingDisabled}
                                              className={styles.textField}
                                              label={t("COMPANIES_MNGMT_INPUT_TITLE")}
                                              name="title"/>
                    </div>
                    <div>
                        <FormTextFieldElement onChangeHandler={formik.handleChange}
                                              touchedElement={formik.touched.usersCapacity}
                                              errorElement={formik.errors.usersCapacity}
                                              value={formik.values.usersCapacity}
                                              disabled={isEditingDisabled}
                                              className={styles.textField}
                                              label={t("COMPANIES_MNGMT_INPUT_MAX_USER_COUNT")}
                                              type="number"
                                              min="0"
                                              name="usersCapacity"/>
                    </div>
                    <div>
                        <FormTextFieldElement onChangeHandler={formik.handleChange}
                                              touchedElement={formik.touched.professionsCapacity}
                                              errorElement={formik.errors.professionsCapacity}
                                              value={formik.values.professionsCapacity}
                                              disabled={isEditingDisabled}
                                              className={styles.textField}
                                              label={t("COMPANIES_MNGMT_INPUT_MAX_RPOF_COUNT")}
                                              type="number"
                                              min="0"
                                              name="professionsCapacity"/>
                    </div>
                </div>
                <div className={styleClasses.dependentArea}>
                    <ExpirationDatePicker forSingleUserPicker={false}
                                          usersList={company ? company.users : []}
                                          expireDate={company ? company.expireDate : ""}
                                          handleExpirationUpdate={handleExpirationUpdate}/>
                </div>
                <div className={styleClasses.dependentArea}>
                    <ProfessionsPickerModal userProfessions={company ? company.professions : []}
                                            saveProfessionsListHandler={handleSavingCompanyProfessions}/>
                </div>
            </div>
        </PageManager>
    );

    if (isEditingDisabled) {
        return (
            <>
                {loading.isLoading && <LoadingSpinner/>}
                {showingForm}
            </>
        )
    } else {
        return (
            <form onSubmit={formik.handleSubmit}>
                {loading.isLoading && <LoadingSpinner/>}
                {showingForm}
            </form>
        );
    }

}