import React from "react"
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import logo from "../../static/otb_logo.png";
import {Toolbar} from "@material-ui/core";
import ProfileMenu from "../ProfileMenu/ProfileMenu";
import {useHistory} from "react-router";
import {COMPANIES_TAB_PAGE, QUESTIONS_TAB_PAGE, USERS_TAB_PAGE} from "../../utils/routeNames";
import classes from "./Header.module.css";
import {useStyles} from "./styles";
import {useTranslation} from "react-i18next";

export default function Header(props) {
    const styles = useStyles();
    const history = useHistory();

    const {t, i18n} = useTranslation();

    const handleChange = (routeUrl) => {
        history.push(routeUrl)
    }


    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <img className={classes.logoImg} src={logo} alt="Логотип"/>
                <h1 className={classes.logoTitle}>{t("TESTS_MANAGEMENT_TITLE")}</h1>
                <Tabs value={props.value}
                      onChange={props.handleChange}
                      classes={{indicator: styles.indicator}}
                      className={classes.tabContainer}>
                    <Tab className={styles.tab} label={t("QUESTIONS_TAB")}
                         onClick={() => handleChange(QUESTIONS_TAB_PAGE)}/>
                    <Tab className={styles.tab} label={t("USERS_TAB")}
                         onClick={() => handleChange(USERS_TAB_PAGE)}/>
                    <Tab className={styles.tab} label={t("COMPANIES_TAB")}
                         onClick={() => handleChange(COMPANIES_TAB_PAGE)}/>
                    <ProfileMenu handleLocalizationChange={props.handleLocalizationChange}
                                 handleTabChange={props.handleChange}/>
                </Tabs>
            </Toolbar>
        </AppBar>);
}