import styleClasses from "../../HomePage.module.css";
import HeadContainer from "../../../../components/Containers/HeadContainer/HeadContainer";
import MainContainer from "../../../../components/Containers/MainContainer/MainContainer";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    SearchInfoInRows,
    SetChangePage,
    SetDefaultTableState,
    SetRowsPerPage,
    SortRowsByFields
} from "../../../../actions/TableActions";
import {requestHandler} from "../../../../infrastructure/requestHandler";
import {USERS_MANAGEMENT_PAGE} from "../../../../utils/routeNames";
import {CreateNewUser, FetchUsersTableData} from "../../../../actions/UserActions";
import classes from "./UsersTable.module.css";
import TableHeader from "../../../../components/TableHeader/TableHeader";
import PreviewTable from "../../../../components/PreviewTable/PreviewTable";
import {TableCell, Typography} from "@material-ui/core";
import ActiveDot from "../../../../components/ActiveDot/ActiveDot";
import UserTableActions from "../../../../components/UserTableActions/UserTableActions";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

function getHeadCellsWithTranslation(t) {
    return [
        {id: "name", align: "left", label: t("USERS_TABLE_HEADER_NAME"), sortArea: "ascending"},
        {id: "email", align: "left", label: t("USERS_TABLE_HEADER_EMAIL"), sortArea: "ascending"},
        {id: "Role", align: "left", label: t("USERS_TABLE_HEADER_ROLE"), sortArea: "ascending"},
        {id: "ExpireDate", align: "left", label: t("USERS_TABLE_HEADER_EXPIRES"), sortArea: "ascending"},
        {id: "company", align: "left", label: t("USERS_TABLE_HEADER_COMPANY"), sortArea: "ascending"},
        {id: "blocked", align: "center", label: t("USERS_TABLE_HEADER_BLOCKED"), sortArea: "ascending"},
        {id: "Action", align: "right", label: "", sortArea: "none"}
    ];
}

function prepareTableCells(rowsData, setParentState, parentState) {
    let preparedCells = [];

    rowsData.map(row => {
        preparedCells.push([
            <TableCell className={classes.nameCell}><Typography
                className={classes.textArea}>{row.name}</Typography></TableCell>,
            <TableCell className={classes.emailCell}><Typography
                className={classes.textArea}>{row.email}</Typography></TableCell>,
            <TableCell className={classes.roleCell}><Typography
                className={classes.textArea}>{row.role}</Typography></TableCell>,
            <TableCell className={classes.expireDateCell}><Typography
                className={classes.textArea}>{row.expireDate ? new Date(row.expireDate).toLocaleDateString(): ""}</Typography></TableCell>,
            <TableCell className={classes.companyCell}><Typography
                className={classes.textArea}>{row.companyTitle}</Typography></TableCell>,
            <TableCell align="center" className={classes.blockedCell}><ActiveDot isActive={row.blocked}/></TableCell>,
            <TableCell align="right">{<UserTableActions setParentState={setParentState} parentState={parentState}
                                                            userId={row.id} isBlocked={row.blocked}/>}</TableCell>,
        ]);
    })

    return preparedCells;
}

export default function UsersTable() {

    const history = useHistory();
    const dispatch = useDispatch();
    const [stateUpdated, setUpdatingState] = useState(false);
    const tableState = useSelector(state => state.TableReduce);

    const {t} = useTranslation();

    useEffect(() => {
        dispatch(SetDefaultTableState());
    }, []);

    useEffect(() => {
        requestHandler(FetchUsersTableData(tableState), dispatch);
    }, [stateUpdated])

    const handleSortAction = (orderBy, isAsc) => {
        dispatch(SortRowsByFields(orderBy, isAsc));
        setUpdatingState(!stateUpdated);
    }

    const handleChangePage = (event, newPage) => {
        dispatch(SetChangePage(newPage));
        setUpdatingState(!stateUpdated);
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(SetRowsPerPage(parseInt(event.target.value, 10)));
        setUpdatingState(!stateUpdated);
    };

    const handleSearchInput = ({value}) => {
        dispatch(SearchInfoInRows(value));
        setUpdatingState(!stateUpdated);
    }

    const handleButtonClick = async () => {
        var result = await requestHandler(CreateNewUser(), dispatch)

        history.push(USERS_MANAGEMENT_PAGE + `/${result.id}`);
    }

    return (
        <div className={styleClasses.containerBg}>
            <HeadContainer>
                <div className={classes.header}>
                    <TableHeader buttonText={t("USERS_TABLE_BUTTON")}
                                 handleButtonClick={handleButtonClick}
                                 handleSearchInput={handleSearchInput}/>
                </div>
            </HeadContainer>
            <MainContainer>
                <PreviewTable rowsPerPage={tableState.takeRows}
                              orderBy={tableState.sortField}
                              isAscending={tableState.sortAscending}
                              handleSkippingRows={handleChangePage}
                              handleSort={handleSortAction}
                              currentPage={tableState.currentPage}
                              itemsCount={tableState.tableTotalDataCount}
                              tableHeaderItems={getHeadCellsWithTranslation(t)}
                              preparedTableData={prepareTableCells(tableState.tableData, setUpdatingState, stateUpdated)}
                              handleTakingRows={handleChangeRowsPerPage}/>
            </MainContainer>
        </div>
    );
}