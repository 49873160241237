import FormControl from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {GetOrdinance, GetOrdinances} from "../../actions/OrdinanceActions";
import * as yup from "yup";
import PropTypes from "prop-types";
import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import {requestHandler} from "../../infrastructure/requestHandler";
import {useStyles} from "./styles";
import styleClasses from "./TopicSectionManagementComponent.module.css";
import FormSelectionElement from "../FormSelectionElement/FormSelectionElement";
import {useTranslation} from "react-i18next";

function TopicSectionManagementComponent(props) {

    const [ordinances, setOrdinances] = useState([]);
    const [chapters, setChapters] = useState([]);
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(async () => {
        const result = await requestHandler(GetOrdinances(), dispatch);
        let ordinancesArr = [];
        let chaptersArr = [];
        result.ordinances.forEach(ordinance => {

            ordinancesArr.push({
                key: ordinance.id,
                value: ordinance.title
            });

            if (props.topicSection !== undefined) {
                if (props.topicSection.ordinanceId === ordinance.id) {
                    ordinance.chapters.map(chapter => {
                        chaptersArr.push({
                            key: chapter.id,
                            value: chapter.title
                        });
                    });
                }
            }
        });

        setOrdinances(ordinancesArr);
        setChapters(chaptersArr);
    }, []);


    const handleSubmitAction = async (values) => {
        props.formSubmitHandler(values);
    }

    const handleOrdinanceChoice = async (e) => {
        formik.handleChange(e);

        const ordinanceId = e.target.value;
        await setupChaptersList(ordinanceId);
    }

    const setupChaptersList = async (ordinanceId) => {
        const result = await GetOrdinance(ordinanceId);

        let chapterArr = [];

        result.chapters.forEach(chapter => {
            chapterArr.push({
                key: chapter.id,
                value: chapter.title
            });
        });

        setChapters(chapterArr);
    }

    const validationSchema = yup.object({
        ordinance: yup.string(t('ADMINISTRATION_PROFESSIONS_TOPIC_ORDINANCE'))
                            .required(t('ADMINISTRATION_PROFESSIONS_TOPIC_VALIDATION_ORDINANCE')).nullable(),
        chapter: yup.string(t('ADMINISTRATION_PROFESSIONS_TOPIC_CHAPTER'))
                            .required(t('ADMINISTRATION_PROFESSIONS_TOPIC_VALIDATION_CHAPTER')).nullable(),
        title: yup.string(t('ADMINISTRATION_PROFESSIONS_TOPIC_FORM_TITLE'))
                            .required(t('ADMINISTRATION_PROFESSIONS_TOPIC_VALIDATION_TITLE')).nullable()
    });

    const formik = useFormik({
        onSubmit: handleSubmitAction,
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            ordinance: props.topicSection ? props.topicSection.ordinanceId : "",
            chapter: props.topicSection ? props.topicSection.chapterId : "",
            title: props.topicSection ? props.topicSection.title : "",
        }
    });

    const topicSectionComponent = (<>
        <div className={styleClasses.dropdownsArea}>
            <div>
                <FormControl variant="standard" className={classes.selectElement}>
                    <FormSelectionElement
                        name="ordinance"
                        value={formik.values.ordinance}
                        errorElement={formik.errors.ordinance}
                        touchedElement={formik.touched.ordinance}
                        label={t("ADMINISTRATION_PROFESSIONS_TOPIC_ORDINANCE_LABEL")}
                        onChangeHandler={handleOrdinanceChoice}
                        selectionList={ordinances}/>
                </FormControl>

                <FormControl variant="standard" className={classes.selectElement}>
                    <FormSelectionElement
                        name="chapter"
                        value={formik.values.chapter}
                        errorElement={formik.errors.chapter}
                        touchedElement={formik.touched.chapter}
                        label={t("ADMINISTRATION_PROFESSIONS_TOPIC_ORDINANCE_CHAPTER")}
                        onChangeHandler={formik.handleChange}
                        selectionList={chapters}/>
                </FormControl>
            </div>
            <div>
                {props.buttonElement}
            </div>
        </div>

        <div className={styleClasses.titleArea}>
            <TextField
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                label={t("ADMINISTRATION_PROFESSIONS_TOPIC_ORDINANCE_TITLE")}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                className={classes.textField}/>
        </div>
    </>);

    if (props.isEditable) {
        return (
            <form aria-disabled={true} onSubmit={formik.handleSubmit}>
                {topicSectionComponent}
            </form>
        )
    } else {
        return topicSectionComponent
    }
}

TopicSectionManagementComponent.propTypes = {
    isEditable: PropTypes.bool.isRequired,
    buttonElement: PropTypes.element.isRequired,

    topicSection: PropTypes.object.isRequired,

    formSubmitHandler: PropTypes.func,
}

export default TopicSectionManagementComponent;