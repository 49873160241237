export const PAGE_IS_LOADING = "PAGE_LOADING";
export const PAGE_NOT_LOADING = "PAGE_NOT_LOADING";

export const SNACKBAR_ERROR_APPEARED = "SNACKBAR_ERROR_APPEARED";
export const SNACKBAR_SUCCESS_APPEARED = "SNACKBAR_SUCCESS_APPEARED";
export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR";

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const SET_DEFAULT_TABLE_STATE = "SET_DEFAULT_TABLE_STATE";
export const SET_TABLE_SORT_PARAMETER = "SET_TABLE_SORT_PARAMETER";
export const SET_TABLE_SEARCH_PARAMETER = "SET_TABLE_SEARCH_PARAMETER";
export const SET_CURRENT_PAGE = "SET_SKIPPING_ROWS";
export const SET_TAKING_ROWS = "SET_TAKING_ROWS";
export const SET_TABLE_DATA = "SET_TABLE_DATA";

export const QUESTION_SET_DATA = "QUESTION_SET_DATA";

export const SET_UPDATING_PAGE_STATUS = "SET_UPDATING_PAGE_STATUS";
export const SET_VIEW_PAGE_STATUS = "SET_VIEW_PAGE_STATUS";
export const SET_NEW_PAGE_STATUS = "SET_NEW_PAGE_STATUS";

export const SET_ANSWERS_LIST = "SET_ANSWERS_LIST";
export const ADD_ANSWER = "ADD_ANSWER";
export const UPDATE_ANSWER = "UPDATE_ANSWER";
export const DELETE_ANSWER = "DELETE_ANSWER";
export const RESET_ANSWERS_LIST = "RESET_ANSWERS_LIST";

export const SET_ORDINANCES_LIST = "SET_ORDINANCES_LIST";
export const UPDATE_ORDINANCE = "UPDATE_ORDINANCE";
export const DELETE_ORDINANCE = "DELETE_ORDINANCE";
export const ADD_ORDINANCE = "ADD_ORDINANCE";
export const ADD_ORDINANCE_CHAPTER = "ADD_ORDINANCE_CHAPTER";
export const UPDATE_ORDINANCE_CHAPTER = "UPDATE_ORDINANCE_CHAPTER";
export const DELETE_ORDINANCE_CHAPTER = "DELETE_ORDINANCE_CHAPTER";

export const SET_PROFESSIONS_LIST = "SET_PROFESSIONS_LIST";
export const ADD_PROFESSION = "ADD_PROFESSION";
export const UPDATE_PROFESSION = "UPDATE_PROFESSION";
export const DELETE_PROFESSION  = "DELETE_PROFESSION";
export const ADD_PROFESSION_TOPIC = "ADD_PROFESSION_TOPIC";
export const UPDATE_PROFESSION_TOPIC = "UPDATE_PROFESSION_TOPIC";
export const DELETE_PROFESSION_TOPIC = "DELETE_PROFESSION_TOPIC";
export const ADD_PROFESSION_TOPIC_SECTION = "ADD_PROFESSION_TOPIC_SECTION";
export const UPDATE_PROFESSION_TOPIC_SECTION = "UPDATE_PROFESSION_TOPIC_SECTION";
export const DELETE_PROFESSION_TOPIC_SECTION = "DELETE_PROFESSION_TOPIC_SECTION";

export const GET_QUESTION_IMPORTS = "GET_QUESTION_IMPORTS";
export const UPLOAD_QUESTIONS_DOCUMENT = "UPLOAD_QUESTIONS_DOCUMENT";
export const START_IMPORT_QUESTIONS = "START_IMPORT_QUESTIONS";