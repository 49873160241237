import api from "../infrastructure/Api";
import {
    ADD_PROFESSION_TOPIC,
    DELETE_PROFESSION_TOPIC,
    UPDATE_PROFESSION_TOPIC
} from "./types/ActionTypes";

export const CreateTopic = (professionId) => async (dispatch) => {
    const topic = await api.createTopic({professionId});

    dispatch({
        type: ADD_PROFESSION_TOPIC,
        professionId: topic.professionId,
        id: topic.id,
        title: topic.title,
        topicSections: topic.topicSections
    });
}

export const UpdateTopic = (professionId, id, title) => async (dispatch) => {
    const topic = await api.updateTopic({professionId, id, title});

    dispatch({
        type: UPDATE_PROFESSION_TOPIC,
        professionId: topic.professionId,
        id: topic.id,
        title: topic.title,
        topicSections: topic.topicSections
    });
}

export const DeleteTopic = (professionId, id) => async (dispatch) => {
    const topic = await api.deleteTopic({professionId, id});

    dispatch({
        type: DELETE_PROFESSION_TOPIC,
        professionId: topic.professionId,
        id: topic.id
    });
}