import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    selectElement: {
        'min-width': "250px",
        'margin-right': "30px",
        maxWidth: '47vw',
        '&:clicked': {
            'border-color': "var(--color-red)"
        },
    },
    textField: {
        'margin-bottom': "15px",
        'width': "100%"
    }
}));