import PropTypes from "prop-types";
import TableAction from "../TableAction/TableAction";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {requestHandler} from "../../infrastructure/requestHandler";
import {useState} from "react";
import {DeleteCompany, UpdateCompany, ViewCompany} from "../../actions/CompanyActions";
import {COMPANIES_MANAGEMENT_PAGE} from "../../utils/routeNames";
import {useTranslation} from "react-i18next";

function fillAction(key, label, handleClickEvent) {
    return {key, label, handleClickEvent};
}

function CompanyTableActions(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const {t, i18n} = useTranslation();

    const [anchor, setAnchorEl] = useState(false);

    const handleCompanyViewAction = (id) => {
        dispatch(ViewCompany());
        history.push(COMPANIES_MANAGEMENT_PAGE + `/${id}`);
    }

    const handleCompanyEditAction = (id) => {
        dispatch(UpdateCompany());
        history.push(COMPANIES_MANAGEMENT_PAGE + `/${id}`);
    }

    const handleCompanyDeleteAction = async (id) => {
        await requestHandler(DeleteCompany(id), dispatch);
        props.setParentState(!props.parentState);
        setAnchorEl(null);
    }

    const companyActions = [
        fillAction("view", t("COMPANIES_TABLE_ACTION_VIEW"), () => handleCompanyViewAction(props.companyId)),
        fillAction("edit", t("COMPANIES_TABLE_ACTION_EDIT"), () => handleCompanyEditAction(props.companyId)),
        fillAction("delete", t("COMPANIES_TABLE_ACTION_DELETE"), () => handleCompanyDeleteAction(props.companyId))
    ];

    return (
        <TableAction anchor={anchor}
                     setAnchorEl={setAnchorEl}
                     actionItems={companyActions}/>
    )
}

CompanyTableActions.propTypes = {
    companyId: PropTypes.string.isRequired,
    setParentState: PropTypes.func,
    parentState: PropTypes.bool
}

export default CompanyTableActions;