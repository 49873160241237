import React from "react";
import PropTypes from "prop-types";
import {Chip, Paper} from "@material-ui/core";
import {useStyles} from "./styles";

function ProfessionChipsArea(props) {
    const classes = useStyles();

    const handleDelete = (profession) => () => {
        props.handleDelete(profession)
    };

    return (
        <Paper component="ul" className={classes.root}>
            {props.professions.map((profession) => {
                return (
                    <li key={profession.id}>
                        <Chip
                            label={profession.title}
                            onDelete={props.areaDisabled ? undefined : handleDelete(profession)}
                            className={classes.chip}
                        />
                    </li>
                )
            })}
        </Paper>
    );
}

ProfessionChipsArea.propTypes = {
    professions: PropTypes.array.isRequired,
    handleDelete: PropTypes.func,
    areaDisabled: PropTypes.bool.isRequired
};

export default ProfessionChipsArea;