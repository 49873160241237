import * as action from "./types/ActionTypes";
import * as constants from "../utils/constants";

import api from "../infrastructure/Api";

export const LoginUser = ({Login, Password}) => async (dispatch) => {
    const data = await api.loginUser({Login, Password});

    localStorage.setItem(
        constants.LOCAL_STORAGE_KEY,
        JSON.stringify({
            uid: data.id,
            token: data.token,
            username: data.name
        })
    );

    dispatch({
        type: action.LOGIN_USER,
        username: data.name,
        token: data.token,
        uid: data.id,
    });

    return !!data.token;
};

export const LogoutUser = () => async (dispatch) => {
    localStorage.removeItem(constants.LOCAL_STORAGE_KEY);
    dispatch({
        type: action.LOGOUT_USER,
    });
};
