import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    SearchInfoInRows,
    SetChangePage,
    SetDefaultTableState,
    SetRowsPerPage,
    SortRowsByFields
} from "../../../../actions/TableActions";
import {requestHandler} from "../../../../infrastructure/requestHandler";
import styleClasses from "../../HomePage.module.css";
import HeadContainer from "../../../../components/Containers/HeadContainer/HeadContainer";
import classes from "./CompaniesTable.module.css";
import TableHeader from "../../../../components/TableHeader/TableHeader";
import MainContainer from "../../../../components/Containers/MainContainer/MainContainer";
import PreviewTable from "../../../../components/PreviewTable/PreviewTable";
import {TableCell, Typography} from "@material-ui/core";
import {CreateNewCompany, FetchCompaniesTableData} from "../../../../actions/CompanyActions";
import CompanyTableActions from "../../../../components/CompanyTableActions/CompanyTableActions";
import {COMPANIES_MANAGEMENT_PAGE} from "../../../../utils/routeNames";
import {useTranslation} from "react-i18next";

function getHeadCellsWithTranslation(t) {
    return [
        {id: "title", align: "left", label: t("COMPANIES_TABLE_HEADER_COMPANY"), sortArea: "ascending"},
        {id: "Users", align: "right", label: t("COMPANIES_TABLE_HEADER_EMPLOYEES"), sortArea: "ascending"},
        {id: "professions", align: "right", label: t("COMPANIES_TABLE_HEADER_PROFESSIONS"), sortArea: "none"},
        {id: "userLimit", align: "right", label: t("COMPANIES_TABLE_HEADER_USERLIMIT"), sortArea: "none"},
        {id: "professionsLimit", align: "right", label: t("COMPANIES_TABLE_HEADER_PROFESSIONSLIMIT"), sortArea: "none"},
        {id: "Action", align: "right", label: "", sortArea: "none"}
    ];
}

function prepareTableCells(rowsData, setParentState, parentState) {
    let preparedCells = [];

    rowsData.map(row => {
        preparedCells.push([
            <TableCell className={classes.titleCell}><Typography
                className={classes.textArea}>{row.title}</Typography></TableCell>,
            <TableCell className={classes.usersCell} align="right"><Typography
                className={classes.textArea}>{row.users}</Typography></TableCell>,
            <TableCell className={classes.profsCell} align="right"><Typography
                className={classes.textArea}>{row.professions}</Typography></TableCell>,
            <TableCell className={classes.ucapCell} align="right"><Typography
                className={classes.textArea}>{row.usersCapacity}</Typography></TableCell>,
            <TableCell className={classes.profcapCell} align="right"><Typography
                className={classes.textArea}>{row.professionsCapacity}</Typography></TableCell>,
            <TableCell align="right">{<CompanyTableActions companyId={row.id} parentState={parentState}
                                                           setParentState={setParentState}/>}</TableCell>,
        ]);
    })

    return preparedCells;
}

export default function CompaniesTable() {

    const history = useHistory();
    const dispatch = useDispatch();
    const [stateUpdated, setUpdatingState] = useState(false);
    const tableState = useSelector(state => state.TableReduce);

    const {t, i18n} = useTranslation();

    useEffect(() => {
        dispatch(SetDefaultTableState());
    }, []);

    useEffect(() => {
        requestHandler(FetchCompaniesTableData(tableState), dispatch);
    }, [stateUpdated])

    const handleSortAction = (orderBy, isAsc) => {
        dispatch(SortRowsByFields(orderBy, isAsc));
        setUpdatingState(!stateUpdated);
    }

    const handleChangePage = (event, newPage) => {
        dispatch(SetChangePage(newPage));
        setUpdatingState(!stateUpdated);
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(SetRowsPerPage(parseInt(event.target.value, 10)));
        setUpdatingState(!stateUpdated);
    };

    const handleSearchInput = ({value}) => {
        dispatch(SearchInfoInRows(value));
        setUpdatingState(!stateUpdated);
    }

    const handleButtonClick = async () => {
        var result = await requestHandler(CreateNewCompany(), dispatch)

        history.push(COMPANIES_MANAGEMENT_PAGE + `/${result.id}`);
    }

    return (
        <div className={styleClasses.containerBg}>
            <HeadContainer>
                <div className={classes.header}>
                    <TableHeader buttonText={t("COMPANIES_TABLE_BUTTON_TEXT")}
                                 handleButtonClick={handleButtonClick}
                                 handleSearchInput={handleSearchInput}/>
                </div>
            </HeadContainer>
            <MainContainer>
                <PreviewTable rowsPerPage={tableState.takeRows}
                              orderBy={tableState.sortField}
                              isAscending={tableState.sortAscending}
                              handleSkippingRows={handleChangePage}
                              handleSort={handleSortAction}
                              currentPage={tableState.currentPage}
                              itemsCount={tableState.tableTotalDataCount}
                              tableHeaderItems={getHeadCellsWithTranslation(t)}
                              preparedTableData={prepareTableCells(tableState.tableData, setUpdatingState, stateUpdated)}
                              handleTakingRows={handleChangeRowsPerPage}/>
            </MainContainer>
        </div>
    );
}