import api from "../infrastructure/Api";
import {
    ADD_ORDINANCE_CHAPTER,
    UPDATE_ORDINANCE_CHAPTER,
    DELETE_ORDINANCE_CHAPTER
} from "./types/ActionTypes";

export const CreateChapter = (ordinanceId) => async (dispatch) => {
    const chapter = await api.createChapter({ordinanceId});

    dispatch({
        type: ADD_ORDINANCE_CHAPTER,
        id: chapter.id,
        ordinanceId: chapter.ordinanceId,
        title: chapter.title,
        header: chapter.header
    })
}

export const UpdateChapter = (id, title, header) => async (dispatch) => {
    const chapter = await api.updateChapter({id, title, header})

    dispatch({
        type: UPDATE_ORDINANCE_CHAPTER,
        id: chapter.id,
        ordinanceId: chapter.ordinanceId,
        title: chapter.title,
        header: chapter.header
    })
}

export const DeleteChapter = (id) => async (dispatch) => {
    const chapter = await api.deleteChapter({id});

    dispatch({
        type: DELETE_ORDINANCE_CHAPTER,
        id: chapter.id,
        ordinanceId: chapter.ordinanceId,
    })
}