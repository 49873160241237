import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    submitButton: {
        'color': "var(--success-primary)",
        padding: '0',
        '&:hover': {
            'color': "var(--success-secondary)",
            background: "transparent",
        },
    }
}));