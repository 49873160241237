import CompaniesTable from "./Table/CompaniesTable";
import CompanyManagementPage from "./Management/CompanyManagementPage";
import {
    COMPANIES_MANAGEMENT_ROUTE_PAGE,
    COMPANIES_TAB_PAGE
} from "../../../utils/routeNames";

export default [
    ManageApplicationRoutes(COMPANIES_TAB_PAGE, CompaniesTable, true),
    ManageApplicationRoutes(COMPANIES_MANAGEMENT_ROUTE_PAGE, CompanyManagementPage, true),
];

function ManageApplicationRoutes(path, component, isExact) {
    return {
        path,
        component,
        isExact
    };
}