import PropTypes from "prop-types";
import {IconButton, TextField, Tooltip} from "@material-ui/core";
import styleClasses from "./TopicSectionPreview.module.css";
import CreateIcon from '@material-ui/icons/Create';
import {useStyles} from './styles';

function TopicSectionPreview(props) {
    const classes = useStyles();

    const selectTopicSectionHandler = () => {
        props.selectTopicSectionHandler(props.professionId, props.topicId, props.topicSection.id);
    }

    return (
        <div className={styleClasses.root}>
            <div>
                <TextField disabled value={props.topicSection.title}
                           variant="outlined"
                           className={classes.titleElement}/>
                <Tooltip title={props.topicSection.ordinanceTitle}>
                    <TextField disabled value={props.topicSection.ordinanceTitle}
                               variant="outlined"
                               className={classes.dropdownElements}/>
                </Tooltip>
                <Tooltip title={props.topicSection.chapterTitle}>
                    <TextField disabled value={props.topicSection.chapterTitle}
                               variant="outlined"
                               className={classes.dropdownElements}/>
                </Tooltip>
            </div>
            <div>
                <IconButton className={classes.buttonElement}
                            onClick={selectTopicSectionHandler}>
                    <CreateIcon/>
                </IconButton>
            </div>
        </div>
    );
}

TopicSectionPreview.propTypes = {
    selectTopicSectionHandler: PropTypes.func.isRequired,

    topicId: PropTypes.string.isRequired,
    professionId: PropTypes.string.isRequired,

    topicSection: PropTypes.object.isRequired,
}

export default TopicSectionPreview;