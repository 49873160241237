import React from 'react';
import styles from './NavContainer.module.css'
import PropTypes from 'prop-types'


export default function NavContainer(props) {

  return (
    <div className={styles.navContainer}>
      {props.children}
    </div>
  );
}

NavContainer.propTypes = {
  children: PropTypes.element.isRequired
}