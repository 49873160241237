import PropTypes from "prop-types";
import styles from "./AdministratingLeftSidebar.module.css";
import React from "react";

function AdministratingLeftSidebar(props) {
    return (
        <div className={styles.rootArea}>
            {props.children}
        </div>
    );
}

AdministratingLeftSidebar.propTypes = {
    children: PropTypes.element.isRequired
}

export default AdministratingLeftSidebar;