import PropTypes from "prop-types";
import {Button, Checkbox, InputAdornment, TextField, withStyles} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import React from "react";
import {useStyles} from "./styles";
import styleClasses from "./AnswerInput.module.css";
import {DelayInput} from "react-delay-input/lib/Component";
import SearchField from "../TableHeader/SearchField/SearchField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from '@material-ui/icons/Check';
import {useTranslation} from "react-i18next";

const ValidAnswerTextField = withStyles({
    root: {
        marginBottom: "10px",
        '& label.Mui-focused': {
            color: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'var(--success-primary)',
                borderWidth: 2
            },
            '&.Mui-focused fieldset': {
                borderColor: 'var(--success-secondary)',
            },
        },
    }
})(TextField);

const GreenCheckbox = withStyles({
    root: {
        '&$checked': {
            color: 'var(--success-secondary)',
        },
    },
    checked: {},
})(Checkbox);

export default function AnswerInput(props) {
    const styles = useStyles();
    const {t} = useTranslation();

    if (props.isCorrect === false) {
        return (<TextField variant="outlined"
                           size="small"
                           placeholder={t("ANSWERS_MODAL_INPUT_PLACEHOLDER")}
                           className={styles.inputMargin}
                           multiline
                           onChange={props.onChange}
                           value={props.value}
                           InputProps={{
                               endAdornment: (
                                   <InputAdornment position="end">
                                       <IconButton aria-label="delete"
                                                   onClick={() => props.deleteAnswerHandler(props.id)}>
                                           <DeleteIcon className={styleClasses.deleteIcon} fontSize="small"/>
                                       </IconButton>
                                   </InputAdornment>
                               ),
                               startAdornment: (
                                   <InputAdornment position="start">
                                       <GreenCheckbox
                                           checked={props.isCorrect}
                                           onChange={(e) => props.setCorrectAnswerHandler({
                                               value: props.value,
                                               isCorrect: true,
                                               id: props.id
                                           })}
                                       />
                                   </InputAdornment>
                               )
                           }}
        />);
    }

    return (
        <ValidAnswerTextField variant="outlined"
                              size="small"
                              placeholder="Search"
                              onChange={props.onChange}
                              multiline
                              value={props.value}
                              InputProps={{
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <IconButton aria-label="delete"
                                                      onClick={() => props.deleteAnswerHandler(props.id)}>
                                              <DeleteIcon className={styleClasses.deleteIcon} fontSize="small"/>
                                          </IconButton>
                                      </InputAdornment>
                                  ),
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          <GreenCheckbox
                                              checked={props.isCorrect}
                                              onChange={(e) => props.setCorrectAnswerHandler({
                                                  value: props.value,
                                                  isCorrect: false,
                                                  id: props.id
                                              })}
                                          />
                                      </InputAdornment>
                                  )
                              }}
        />
    );
}

AnswerInput.props = {
    id: PropTypes.string.isRequired,
    isCorrect: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,

    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,

    deleteHandler: PropTypes.func.isRequired,
    setAsRightAnswerHandler: PropTypes.func.isRequired
}