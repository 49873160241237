import {TextField} from "@material-ui/core";
import React from "react";

export default function FormTextareaElement({
                                     name, label, disabled, value, onChangeHandler,
                                     touchedElement, errorElement, styles, minRows
                                 }) {
    return (
        <>
            <TextField
                name={name}
                disabled={disabled}
                value={value}
                onChange={onChangeHandler}
                error={touchedElement && Boolean(errorElement)}
                helperText={touchedElement && errorElement}
                className={styles.textField}
                minRows={minRows}
                label={label}
                InputLabelProps={{
                    shrink: true,
                }}
                multiline
                variant="outlined"/>
        </>);
}