import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerContainer: {
        alignSelf: 'center',
        display: 'flex',
        width: '100%',
        justifyContent: 'right'
    },
    iconPaddings: {
        marginRight: 7
    },
    importButton: {
        color: "white",
        height: "40px",
        backgroundColor: "var(--color-red)",
        '&:hover': {
            backgroundColor: "var(--color-red-hover)",
        },
        marginRight: "15px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "5px",
        padding: theme.spacing(2, 4, 3),
        minWidth: "550px"
    },
    createImportButton: {
        width: "100%",
        'color': "var(--success-primary)",
        borderColor: "var(--success-secondary)"
    },
    modalContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    inputElement: {
        marginBottom: "15px",
    },
    uploadDocumentButton: {
        'color': "var(--color-red)",
        'border-color': "var(--color-red)",
        marginBottom: 10,
        '&:hover': {
            background: "var(--color-red-transparent-hover)",
        },
    },
}));