import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "5px",
        padding: theme.spacing(2, 4, 3),
        minWidth: "550px"
    },
    manageButton: {
        'color': "var(--color-red)",
        marginTop: "7px",
        borderColor: "var(--color-red)",
        '&:hover': {
            background: "var(--color-red-transparent-hover)",
        },
    },
    updateButton: {
        width: "100%",
        'color': "var(--success-primary)",
        borderColor: "var(--success-secondary)"
    },
    inputElement: {
        marginBottom: "10px"
    },
    datePicker: {
        width: "100%"
    }
}));