import api from "../infrastructure/Api";
import {SET_PROFESSIONS_LIST, ADD_PROFESSION, UPDATE_PROFESSION, DELETE_PROFESSION} from "./types/ActionTypes";

export const GetProfessions = () => async (dispatch) => {
    const data = await api.getProfessions();

    dispatch({
        type: SET_PROFESSIONS_LIST,
        professions: data.professions
    });

    return data;
}

export const CreateProfession = () => async (dispatch) => {
    const profession = await api.createProfession();

    dispatch({
        type: ADD_PROFESSION,
        id: profession.id,
        title: profession.title,
        topics: profession.topics
    });
}

export const UpdateProfession = (id, title) => async (dispatch) => {
    const profession = await api.updateProfession({id, title});

    dispatch({
        type: UPDATE_PROFESSION,
        id: profession.id,
        title: profession.title,
        topics: profession.topics
    });
}

export const DeleteProfession = (id) => async (dispatch) => {
    const profession = await api.deleteProfession({id});

    dispatch({
        type: DELETE_PROFESSION,
        id: profession.id
    });
}