import {InputLabel, MenuItem, Select} from "@material-ui/core";
import React from "react";

export default function FormSelectionElement({
                                  name, label, disabled, value,
                                  onChangeHandler, touchedElement, errorElement, selectionList
                              }) {
    return (
        <>
            <InputLabel shrink id={`${name}-select-label`}>{label}</InputLabel>
            <Select
                name={`${name}`}
                disabled={disabled}
                value={value}
                onChange={onChangeHandler}
                error={touchedElement && Boolean(errorElement)}
                helperText={touchedElement && errorElement}
                labelId={`${name}-select-label`}
                id={`${name}Selection`}
                label={label}>
                {selectionList.map(selection => {
                    return <MenuItem value={selection.key}>{selection.value}</MenuItem>
                })}
            </Select>
        </>
    )
}