import QuestionPage from "./Questions";
import UsersPage from "./Users";
import CompanyPage from "./Companies";
import AdministrationPage from "./Administration";
import {
    QUESTIONS_TAB_PAGE,
    USERS_TAB_PAGE,
    COMPANIES_TAB_PAGE,
    ADMINISTRATION_PAGE
} from "../../utils/routeNames";

export default [
    ManageApplicationRoutes(QUESTIONS_TAB_PAGE, QuestionPage, false, 0),
    ManageApplicationRoutes(USERS_TAB_PAGE, UsersPage, false, 1),
    ManageApplicationRoutes(COMPANIES_TAB_PAGE, CompanyPage, false, 2),
    ManageApplicationRoutes(ADMINISTRATION_PAGE, AdministrationPage, false, -1)
];

function ManageApplicationRoutes(path, component, isExact, tabIndicator) {
    return {
        path,
        component,
        isExact,
        tabIndicator
    };
}