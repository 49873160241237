import React, {useEffect, useState} from "react";
import {Backdrop, Button, Fade, Modal, TextField} from "@material-ui/core";
import {useStyles} from "./styles";
import PropTypes from "prop-types";
import styleClasses from "./PasswordChangeModal.module.css";
import FormTextFieldElement from "../FormTextFieldElement/FormTextFieldElement";
import * as yup from "yup";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";

function PasswordChangeModal(props) {
    const [credentialsData, setCredentialsData] = useState({
        login: "",
        password: "",
        repeatPassword: ""
    });

    const styles = useStyles();
    const {t} = useTranslation();

    useEffect(() => {
        setCredentialsData({
            login: props.login,
            password: "",
            repeatPassword: ""
        })
    }, [props.isModalOpened])

    const handleOpen = () => {
        props.openModal(true);
    };

    const handleClose = () => {
        props.openModal(false);
    };

    const updateUserCredentialsHandler = (values) => {
        const {login, password} = values;
        props.updateUserCredentialsHandler(props.userId, login, password);
    }

    const validationSchema = yup.object({
        login: yup.string().required(t("PASSWORD_MODAL_INPUT_VALIDATION_LOGIN")),
        password: yup.string().required(t('PASSWORD_MODAL_INPUT_VALIDATION_PASSWORD')),
        repeatPassword: yup.string().required(t('PASSWORD_MODAL_INPUT_VALIDATION_REPEAT_PASSWORD'))
            .oneOf([yup.ref('password'), null], t('PASSWORD_MODAL_INPUT_VALIDATION_PASSWORDS_NOTMATCH'))
    });

    const formik = useFormik({
        onSubmit: updateUserCredentialsHandler,
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            login: credentialsData.login,
            password: credentialsData.password,
            repeatPassword: credentialsData.repeatPassword
        },
    });


    return (
        <div>
            <div className={styleClasses.mainElements}>
                <TextField disabled
                           variant="outlined"
                           label={t("PASSWORD_MODAL_INPUT_LOGIN_LABEL")}
                           value={props.login}/>
                <Button variant="outlined" className={styles.manageButton} onClick={handleOpen}>
                    {t("PASSWORD_MODAL_MANAGE_CREDS_BTN")}
                </Button>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={props.isModalOpened}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={props.isModalOpened}>
                    <div className={styles.paper}>
                        <label className={styleClasses.questionHeaderLabel}>
                            {t("PASSWORD_MODAL_TITLE")}
                        </label>
                        <div className={styleClasses.separationLine}/>
                        <form onSubmit={formik.handleSubmit} className={styleClasses.formArea}>
                            <FormTextFieldElement onChangeHandler={formik.handleChange}
                                                  touchedElement={formik.touched.login}
                                                  errorElement={formik.errors.login}
                                                  value={formik.values.login}
                                                  label={t("PASSWORD_MODAL_INPUT_LOGIN_LABEL")}
                                                  className={styles.inputElement}
                                                  disabled={false}
                                                  name="login"/>
                            <FormTextFieldElement onChangeHandler={formik.handleChange}
                                                  touchedElement={formik.touched.password}
                                                  errorElement={formik.errors.password}
                                                  value={formik.values.password}
                                                  label={t("PASSWORD_MODAL_INPUT_PASSWORD_LABEL")}
                                                  type="password"
                                                  className={styles.inputElement}
                                                  disabled={false}
                                                  name="password"/>
                            <FormTextFieldElement onChangeHandler={formik.handleChange}
                                                  touchedElement={formik.touched.repeatPassword}
                                                  errorElement={formik.errors.repeatPassword}
                                                  value={formik.values.repeatPassword}
                                                  label={t("PASSWORD_MODAL_INPUT_REPEAT_PASSWORD_LABEL")}
                                                  type="password"
                                                  className={styles.inputElement}
                                                  disabled={false}
                                                  name="repeatPassword"/>
                            <Button variant="outlined"
                                    type="submit"
                                    className={styles.updateButton}>
                                {t("PASSWORD_MODAL_INPUT_UPDATE_CREDS_BTN")}
                            </Button>
                        </form>
                        <div className={styleClasses.separationLine}/>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

PasswordChangeModal.propTypes = {
    login: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    updateUserCredentialsHandler: PropTypes.func,

    isModalOpened: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired
}

export default PasswordChangeModal;