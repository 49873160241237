import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E7EAEE'
            },
            '&.Mui-focused fieldset': {
                borderColor: 'var(--color-red)',
            },
        },
    },
});