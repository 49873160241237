import React from "react";
import {withStyles} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import {DelayInput} from "react-delay-input/lib/Component";
import SearchField from "./SearchField/SearchField";

const {Button} = require("@material-ui/core");

const ElementButton = withStyles((theme) => ({
    root: {
        color: "white",
        height: "40px",
        backgroundColor: "var(--color-red)",
        '&:hover': {
            backgroundColor: "var(--color-red-hover)",
        },
        marginRight: "15px"
    },
}))(Button);

export default function TableHeader(props) {
    return (
        <div>
            <ElementButton startIcon={<AddIcon/>}
                           onClick={() => props.handleButtonClick()}>
                {props.buttonText}</ElementButton>
            <DelayInput
                delayTimeout={300}
                onChange={event => props.handleSearchInput({value: event.target.value})}
                element={SearchField}
            />
        </div>
    );
}

TableHeader.propTypes = {
    buttonText: PropTypes.string.isRequired,
    handleButtonClick: PropTypes.func.isRequired,
    handleSearchInput: PropTypes.func.isRequired
}