import React from 'react';
import PropTypes from 'prop-types';
import TreeView from '@material-ui/lab/TreeView';
import {ContextMenu, MenuItem, ContextMenuTrigger} from "react-contextmenu";

import styleClasses from "./OrdinancesTreeViewData.module.css";

import PlusSquareMenuIcon from "../PlusSquareMenuIcon/PlusSquareMenuIcon";
import MinusSquareMenuIcon from "../MinusSquareMenuIcon/MinusSquareMenuIcon";
import {TreeViewItemNode} from "../TreeViewItemNode/TreeViewItemNode";
import {useStyles} from "./styles";
import {useTranslation} from "react-i18next";

export default function OrdinancesTreeViewData(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    let index = 1;

    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<MinusSquareMenuIcon/>}
            defaultExpandIcon={<PlusSquareMenuIcon/>}>
            {props.ordinances.map((ordinance) => {
                return (
                    <>
                        <ContextMenuTrigger id={`contextmenu_${ordinance.id}`}>
                            <TreeViewItemNode nodeId={index++}
                                              onClick={() => props.selectOrdinanceHandler(ordinance.id)}
                                              label={ordinance.title ? ordinance.title : t("ADMINISTRATION_TREEVIEW_NOT_DEFINED")}>
                                {ordinance.chapters.map((chapter) => {
                                    return (<TreeViewItemNode nodeId={index++}
                                                              onClick={() => props.selectChapterHandler(chapter.id, ordinance.id)}
                                                              label={chapter.title ? chapter.title : t("ADMINISTRATION_TREEVIEW_NOT_DEFINED")}
                                    />);
                                })}
                            </TreeViewItemNode>
                        </ContextMenuTrigger>

                        <ContextMenu id={`contextmenu_${ordinance.id}`}
                                     className={styleClasses.contextMenu}>
                            <MenuItem data={{ordinanceId: ordinance.id}}
                                      onClick={props.createChapterHandler}
                                      className={styleClasses.contextMenuElement}>
                                {t("ADMINISTRATION_ORDINANCES_TREEVIEW_CONTEXT")}
                            </MenuItem>
                        </ContextMenu>
                    </>
                );
            })}
        </TreeView>
    );
}

OrdinancesTreeViewData.propTypes = {
    ordinances: PropTypes.array.isRequired,
    createChapterHandler: PropTypes.func.isRequired,
    selectOrdinanceHandler: PropTypes.func.isRequired,
    selectChapterHandler: PropTypes.func.isRequired
}