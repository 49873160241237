import {
    SET_ANSWERS_LIST,
    ADD_ANSWER,
    UPDATE_ANSWER,
    DELETE_ANSWER
} from "./types/ActionTypes"

import api from "../infrastructure/Api";
import * as action from "./types/ActionTypes";

export const GetQuestionAnswers = (questionId) => async dispatch => {

    const answers = await api.getAnswers({questionId});

    dispatch({
        type: SET_ANSWERS_LIST,
        data: answers
    });

    return answers;
}

export const UpdateAnswer = (id, {text, isCorrect}) => async dispatch => {
    const answer = await api.updateAnswer({id, text, isCorrect})

    dispatch({
        type: UPDATE_ANSWER,
        id: answer.id,
        text: answer.text,
        isCorrect: answer.isCorrect,
    })
}

export const DeleteAnswer = (id) => async dispatch => {
    const answer = await api.deleteAnswer({id});

    dispatch({
        type: DELETE_ANSWER,
        id: answer.id
    })
}

export const CreateAnswer = (questionId) => async dispatch => {
    const answer = await api.createAnswer({questionId});

    dispatch({
        type: ADD_ANSWER,
        id: answer.id,
        text: answer.text,
        isCorrect: answer.isCorrect,
    })
}

export const ResetAnswersList = () => dispatch => {
    dispatch({
        type: action.RESET_ANSWERS_LIST
    })
}