import React, {useEffect} from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import classes from "./ProfileMenu.module.css"
import {useDispatch, useSelector} from "react-redux";
import {LogoutUser} from "../../actions/LoginActions";
import {ListItemIcon, MenuList, Popover, Switch, Typography} from "@material-ui/core";
import {useHistory} from "react-router";
import {ADMINISTRATION_PAGE, LOGIN_PAGE} from "../../utils/routeNames";
import {requestHandler} from "../../infrastructure/requestHandler";
import {useStyles} from "./styles";
import {useTranslation} from "react-i18next";

export default function ProfileMenu(props) {

    const styles = useStyles();
    const history = useHistory();

    const [anchor, setAnchorEl] = React.useState(null);
    const [ruTranslationChecked, setRuTranslation] = React.useState(false);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const localizationLanguage = localStorage.getItem("localization");
        setRuTranslation(localizationLanguage === "ru");
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.LoginReduce);

    const logoutHandler = async () => {
        await requestHandler(LogoutUser(), dispatch);
        history.push(LOGIN_PAGE)
    }

    const handleAdministrationClick = () => {
        history.push(ADMINISTRATION_PAGE);
        props.handleTabChange(-1);
        handleClose();
    }

    const handleLocalizationChange = () => {
        if(ruTranslationChecked) {
            props.handleLocalizationChange("en");
        }else {
            props.handleLocalizationChange("ru");
        }

        setRuTranslation(!ruTranslationChecked);
    }

    return (
        <div>
            <div aria-controls="menu"
                 aria-haspopup="true"
                 className={classes.link}
                 onClick={handleClick}>
                {currentUser.username}
                {anchor ? <ExpandLessIcon className={classes.expandIcon}/> :
                    <ExpandMoreIcon className={classes.expandIcon}/>}
            </div>
            <Popover open={Boolean(anchor)}
                     anchorEl={anchor}
                     onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'center'
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'center'
                     }}>
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList>
                            <MenuItem onClick={() => handleAdministrationClick()}>
                                <ListItemIcon>
                                    <PersonOutlineIcon fontSize="small"
                                                       className={classes.menuIcon}/>
                                </ListItemIcon>
                                <Typography variant="inherit"
                                            className={classes.menuElement}>
                                    {t("ADMINISTRATION_MENU_ITEM")}
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={logoutHandler}>
                                <ListItemIcon>
                                    <ExitToAppIcon fontSize="small"
                                                   className={classes.menuIcon}/>
                                </ListItemIcon>
                                <Typography className={classes.menuElement} variant="inherit">
                                    {t("LOGOUT_MENU_ITEM")}
                                </Typography>
                            </MenuItem>
                            <div className={classes.localizationSwitch}>
                                en
                                <Switch
                                    checked={ruTranslationChecked}
                                    onChange={handleLocalizationChange}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                ru
                            </div>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popover>
        </div>
    );
}