import api from "../infrastructure/Api";
import {ADD_ORDINANCE, DELETE_ORDINANCE, SET_ORDINANCES_LIST, UPDATE_ORDINANCE} from "./types/ActionTypes";

export const GetOrdinances = () => async (dispatch) => {
    const data = await api.getOrdinances();

    dispatch({
        type: SET_ORDINANCES_LIST,
        ordinances: data.ordinances
    });

    return data;
}

export const GetOrdinance = async (id) => {
    return await api.getOrdinance({id});
}

export const UpdateOrdinance = (id, title, header) => async (dispatch) => {
    const ordinance = await api.updateOrdinance({id, title, header});

    dispatch({
        type: UPDATE_ORDINANCE,
        id: ordinance.id,
        title: ordinance.title,
        header: ordinance.header,
        chapters: ordinance.chapters
    });
}

export const DeleteOrdinance = (id) => async (dispatch) => {
    const ordinance = await api.deleteOrdinance({id});

    dispatch({
        type: DELETE_ORDINANCE,
        id: ordinance.id
    });
}

export const CreateOrdinance = () => async (dispatch) => {
    const ordinance = await api.createOrdinance();

    dispatch({
        type: ADD_ORDINANCE,
        id: ordinance.id,
        title: ordinance.title,
        header: ordinance.header,
        chapters: ordinance.chapters
    })
}