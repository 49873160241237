import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from './locales/enTranslations.json';
import ruTranslations from './locales/ruTranslations.json';

const resources = {
    en: {
        translation: enTranslations
    },
    ru: {
        translation: ruTranslations
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;