import React from "react";
import PropTypes from "prop-types";
import styleClasses from "./AdministrationInput.module.css";
import {DelayInput} from "react-delay-input/lib/Component";
import {TextField} from "@material-ui/core";

function AdministrationInput(props) {

    const changeInputValue = (event) => {
        props.changeHandler(event.target.value);
    }

    return (
        <DelayInput delayTimeout={500}
                    value={props.inputValue}
                    onChange={changeInputValue}
                    {...props}
                    element={(props) => {
                        return <TextField variant="outlined"
                                          label={props.inputTitle}
                                          value={props.value}
                                          {...props}
                                          onChange={props.onChange}/>
                    }}
        />
    );
}

AdministrationInput.propTypes = {
    changeHandler: PropTypes.func.isRequired,

    inputTitle: PropTypes.string,
    inputValue: PropTypes.string.isRequired,
}

export default AdministrationInput;