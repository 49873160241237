import React from "react";
import PropTypes from "prop-types";
import {Button} from "@material-ui/core";

import styleClasses from "./AdministrationHeader.module.css";
import {useStyles} from "./styles";

function AdministrationHeader(props) {
    const classes = useStyles();

    return (
        <div className={styleClasses.headerArea}>
            <label className={styleClasses.headerLabel}>{props.header}</label>
            {
                props.buttonClickHandler ?
                    <Button variant="contained"
                            size="medium"
                            onClick={props.buttonClickHandler}
                            className={classes.buttonStyle}>
                        {props.buttonIcon}
                        {props.buttonText}
                    </Button> : <></>
            }
        </div>
    )
}

AdministrationHeader.propTypes = {
    header: PropTypes.string.isRequired,

    buttonText: PropTypes.string,
    buttonIcon: PropTypes.element,
    buttonClickHandler: PropTypes.func
};

export default AdministrationHeader;