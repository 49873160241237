export const user_login = () => "/users/login";

export const questions_list = () => "/questions";
export const get_question = ({id}) => `/questions/${id}`;
export const new_question = () => "/questions";
export const delete_question = ({id}) => `/questions/${id}`;
export const update_question = ({id}) => `/questions/${id}`;

export const get_ordinances = () => "/ordinances";
export const get_ordinance = ({id}) => `/ordinances/${id}`;
export const update_ordinance = ({id}) => `/ordinances/${id}`;
export const delete_ordinance = ({id}) => `/ordinances/${id}`;
export const new_ordinance = () => `/ordinances`;

export const get_image = ({fileName}) => `/image/${fileName}`;

export const get_questionAnswers = ({questionId}) => `/questions/${questionId}/answers`;
export const new_questionAnswers = ({questionId}) => `/questions/${questionId}/answers`;

export const update_Answer = ({id}) => `/answers/${id}`;
export const delete_Answer = ({id}) => `/answers/${id}`;

export const new_chapter = ({ordinanceId}) => `/Ordinances/${ordinanceId}/Chapters`;
export const update_chapter = ({id}) => `/Chapters/${id}`;
export const delete_chapter = ({id}) => `/Chapters/${id}`;

export const get_professions = () => `/professions`;
export const new_professions = () => `/professions`;
export const update_profession = ({id}) => `/professions/${id}`;
export const delete_profession = ({id}) => `/professions/${id}`;

export const new_topic = ({professionId}) => `/professions/${professionId}/topics`;
export const update_topic = ({professionId, id}) => `/professions/${professionId}/topics/${id}`;
export const delete_topic = ({professionId, id}) => `/professions/${professionId}/topics/${id}`;

export const new_section = ({professionId, topicId}) => `/professions/${professionId}/topics/${topicId}/sections`;
export const update_section = ({professionId, topicId, id}) => `/professions/${professionId}/topics/${topicId}/sections/${id}`;
export const delete_section = ({professionId, topicId, id}) => `/professions/${professionId}/topics/${topicId}/sections/${id}`;

export const users_list = () => "/users";
export const delete_user = ({id}) => `/users/${id}`;
export const get_user = ({id}) => `/users/${id}`;
export const update_user = ({id}) => `/users/${id}`;
export const new_user = () => `/users`;
export const update_user_activity = ({id}) => `/users/${id}/activity`;
export const update_user_credentials = ({id}) => `/users/${id}/changePassword`;
export const update_user_expiration = ({id}) => `/users/${id}/expiration`;
export const update_user_professions = ({id}) => `/users/${id}/professions`;

export const companies_list = () => "/companies";
export const get_dropdownCompanies = () => "/companies";
export const new_company = () => `/companies`;
export const delete_company = ({id}) => `/companies/${id}`;
export const get_company = ({id}) => `/companies/${id}`;
export const update_company = ({id}) => `/companies/${id}`;
export const update_company_professions = ({id}) => `/companies/${id}/professions`;
export const update_company_user_expirations = ({id}) => `/companies/${id}/expiration`;

export const get_imports_list = () => '/import';
export const upload_questions_import_document = () => '/import';
export const start_importing_document = (id) => `/import/${id}/start`;