import UsersTable from "./Table/UsersTable";
import UserManagementPage from "./Management/UserManagementPage";
import {USERS_MANAGEMENT_ROUTE_PAGE, USERS_TAB_PAGE} from "../../../utils/routeNames";

export default [
    ManageApplicationRoutes(USERS_TAB_PAGE, UsersTable, true),
    ManageApplicationRoutes(USERS_MANAGEMENT_ROUTE_PAGE, UserManagementPage, true),
];

function ManageApplicationRoutes(path, component, isExact) {
    return {
        path,
        component,
        isExact
    };
}