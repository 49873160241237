import React from "react";
import {Route, Switch} from "react-router-dom";
import routes from "./routes";

export default function QuestionPage() {
    return (
        <div>
            <Switch>
                {routes.map((route) => {
                    return <Route component={route.component}
                                  path={route.path}
                                  exact={route.isExact}/>
                })}
            </Switch>
        </div>
    );
}