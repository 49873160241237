import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    titleElement: {
        'min-width': "360px",
        'margin-right': "10px",
    },
    dropdownElements: {
        'margin-right': "10px",
        'max-width': "150px",
    },
    buttonElement: {
        color: "var(--color-yellow)",
        padding: "0",
        marginTop: "15px",
        marginRight: "5px",
        '&:hover': {
            background: "transparent",
            color: "var(--color-yellow-hover)",
        }
    }
}));